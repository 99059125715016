import React, { useEffect, memo } from 'react';
import styles from './settings.module.scss';
import { SettingButton } from './SettingButton';
import { SettingList } from './SettingList';
import { Dropdown } from 'components/Common/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import {
	hideDivide,
	hideEvents,
	hideGoods,
	hideMultiOrders,
	hideOrder,
	hideOrders,
	hideReportsAllGoods,
	hideReportsCurrent,
	hideReportsPlanning,
	hideReportsQuality,
	showDivide,
	showEventsSetting,
	showGoods,
	showMultiOrders,
	showOrder,
	showOrders,
	showReportsAllGoods,
	showReportsCurrent,
	showReportsPlanning,
	showReportsQuality,
} from 'services/store/setting';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { AppDispatch } from '../../services/store';

type TType =
	| 'goods'
	| 'orders'
	| 'order'
	| 'events'
	| 'multiOrders'
	| 'notifications'
	| 'divide'
	| 'planning'
	| 'allGoods'
	| 'quality'
	| 'current'
	| 'downTimes';
interface ISettingsProps {
	type?: TType;
}

const mapSetHide = (type: TType, dispatch: AppDispatch) => {
	const obj = {
		orders: hideOrders,
		order: hideOrder,
		goods: hideGoods,
		events: hideEvents,
		notifications: hideEvents,
		multiOrders: hideMultiOrders,
		divide: hideDivide,
		allGoods: hideReportsAllGoods,
		quality: hideReportsQuality,
		current: hideReportsCurrent,
		planning: hideReportsPlanning,
		downTimes: () => {},
	};

	dispatch(obj[type]());
};

const mapSetShow = (type: TType, dispatch: AppDispatch) => {
	const obj = {
		orders: showOrders,
		order: showOrder,
		goods: showGoods,
		events: showEventsSetting,
		notifications: showEventsSetting,
		multiOrders: showMultiOrders,
		divide: showDivide,
		allGoods: showReportsAllGoods,
		quality: showReportsQuality,
		current: showReportsCurrent,
		planning: showReportsPlanning,
		downTimes: () => {},
	};

	dispatch(obj[type]());
};

export const Settings = memo(({ type = 'goods' }: ISettingsProps) => {
	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');
	const isDesktop = !isMobile;
	const target = useSelector((state: IStoreState) => state.outside.target);
	const dispatch = useDispatch();

	const getIsSettingsOpen = (type: TType) => {
		const {
			isShowOrders,
			isShowGoods,
			isShowReportsAllGoods,
			isShowReportsCurrent,
			isShowReportsQuality,
			isShowReportsPlanning,
			isShowOrder,
			isShowEvents,
			isShowMultiOrders,
			isShowDivide,
		} = useSelector((state: IStoreState) => state.setting);

		const mapIsShow = {
			orders: isShowOrders,
			order: isShowOrder,
			goods: isShowGoods,
			events: isShowEvents,
			notifications: isShowEvents,
			multiOrders: isShowMultiOrders,
			divide: isShowDivide,
			allGoods: isShowReportsAllGoods,
			quality: isShowReportsQuality,
			current: isShowReportsCurrent,
			planning: isShowReportsPlanning,
			downTimes: false,
		};

		return mapIsShow[type];
	};

	useEffect(() => {
		const list = document.querySelector('[class*=settingList]');
		const button = document.querySelector('[class*=settingButton]');

		if (isDesktop && list && button && !list.contains(target as Node) && !button.contains(target as Node)) {
			mapSetHide(type, dispatch);
		}
	}, [target]);

	return (
		<div className={styles.settings}>
			<Dropdown
				button={
					<SettingButton
						isMobile={isMobile}
						onClick={() => mapSetShow(type, dispatch)}
						isOpen={getIsSettingsOpen(type)}
					/>
				}
				isOpen={getIsSettingsOpen(type)}
			>
				<SettingList type={type} />
			</Dropdown>
		</div>
	);
});
