import React, { FC } from 'react';
import { Portal } from '../Portal';
import { Block } from '../Block';
import { uniqKey } from 'services/utils/react/uniqKey';
import OutsideClickHandler from 'react-outside-click-handler';
import { Links } from '../Modal';
import styles from './list.module.scss';
import { useTranslation } from 'react-i18next';

type TListModalProps = {
	messages: Array<Record<string, string>>;
	onClose: (type: string) => void;
};
export const ModalListResult: FC<TListModalProps> = ({ messages, onClose }) => {
	const { t } = useTranslation();

	return messages[0] ? (
		<Portal>
			<OutsideClickHandler onOutsideClick={() => onClose('')}>
				{messages.map(({ type, text, links }, inx) =>
					text !== '' ? (
						<Block key={uniqKey + inx} onClose={() => onClose(type)} type={type} inx={inx + 1}>
							<div dangerouslySetInnerHTML={{ __html: t(text) }} />

							{links && (
								<div className={styles.links}>
									<Links links={links} />
								</div>
							)}
						</Block>
					) : null
				)}
			</OutsideClickHandler>
		</Portal>
	) : null;
};
