import React, { Fragment, useEffect, useState } from 'react';
import { is10Correct, isCorrected, isUpdate } from 'services/utils/js/getOrderStatus';
import { IStoreState, TCurrentOrder } from 'services/store/interfaces';
import { PropertyBlock } from 'components/InfoCard/PropertyBlock';
import { Status } from 'components/Common/StatusRow';
import { More } from 'components/InfoCard/MoreRow';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderBuyGroup } from 'services/api/getOrderBuyGroup';
import { setConfirmOrder, setReviseOrder } from 'services/store/orders';
import { isAvaibleDate } from 'services/utils/helpers/date';
import { useLanguage } from '../../../../services/contexts/languageContext';

interface ICardProps {
	order: TCurrentOrder;
	setActive: (value: string) => void;
}

export const Cards = ({ order, setActive }: ICardProps) => {
	const language = useLanguage();

	const [isOpen, setIsOpen] = useState(false);
	const [item, setItem] = useState<TCurrentOrder>(order);

	const { OrderBuyGroupId } = item;
	const dispatch = useDispatch();
	const revised = useSelector((state: IStoreState) => state.orders.revisedOrder);
	const confirm = useSelector((state: IStoreState) => state.orders.confirmOrder);
	const filters = useSelector((state: IStoreState) => state.filter.listOrders);

	const isNeedUpdate = OrderBuyGroupId && (revised[OrderBuyGroupId] || confirm?.includes(OrderBuyGroupId));

	useEffect(() => {
		if (!isNeedUpdate) return;

		getOrderBuyGroup({ ...filters, Language: language === 'ru' ? 1 : 2 }).then((res) => {
			const orders = res.table.filter((item) => item.OrderBuyGroupId === OrderBuyGroupId);
			setItem(orders[0]);
		});
		dispatch(setReviseOrder());
		dispatch(setConfirmOrder());

		return () => {};
	});

	const settingsOrders = useSelector((state: IStoreState) => state.setting.listOrders);
	const onClickMore = () => setIsOpen(!isOpen);
	const onClickDetail = () => setActive(String(item.OrderBuyGroupId));

	return (
		<Fragment>
			{settingsOrders.map(({ id, isShow, text }, index) => {
				const count = isOpen ? settingsOrders.length : 5;

				const isCheckx = id === 'Checkx';

				const isUpdated = id === 'Datex' && isUpdate(item) && isAvaibleDate(item.Datex || '');
				const isCorrect = id === 'Datex' && (isCorrected(item) || is10Correct(item)) && isAvaibleDate(item.Datex || '');

				const dateStart = String(item.Datex);
				const dateEnd = String(item.ShipmentDateLast);
				const orderId = String(item.OrderBuyGroupId);

				const value = item[id as keyof TCurrentOrder];

				return (
					isShow &&
					index < count && (
						<PropertyBlock
							key={index}
							name={text}
							value={isCheckx ? <Status order={item} /> : value}
							dateStart={dateStart}
							dateEnd={dateEnd}
							isUpdate={isUpdated}
							isCorrect={isCorrect}
							id={orderId}
						/>
					)
				);
			})}

			<More isOpen={isOpen} onClickMore={onClickMore} onClickDetail={onClickDetail} />
		</Fragment>
	);
};
