import React, { FC, Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import styles from 'components/Content/Orders/FilterOrders/filterOrders.module.scss';
import { InputRegions } from 'components/Common/Input/InputRegions';
import { InputDistricts } from 'components/Common/Input/InputDistricts';
import { InputCities } from 'components/Common/Input/InputCities';
import { TSelectField } from 'components/Common/Input/InputSelect';
import { IStoreState, TResultBlock } from 'services/store/interfaces';
import { useTranslation } from 'react-i18next';

interface IAddressProps {
	setRegion: (value: TSelectField[]) => void;
	setDistrict: (value: TSelectField[]) => void;
	setCities: (value: TSelectField[]) => void;
	onResultBlock: (value: TResultBlock) => void;
	onSetHidden?: (value: boolean | string) => void;
	isEmpty: boolean;
	isReports?: boolean;
}
export const Address: FC<IAddressProps> = ({
	isEmpty,
	onSetHidden,
	setRegion,
	setDistrict,
	setCities,
	onResultBlock,
}) => {
	const { t } = useTranslation();
	const RegionIDS = useSelector((state: IStoreState) => state.address.RegionId);
	const DistrictIDS = useSelector((state: IStoreState) => state.address.DistrictId);
	const { RegionId, DistrictId } = useSelector((state: IStoreState) => state.filter.listOrders);
	const [idRegion, setIdRegions] = useState<string[]>(RegionId || []);
	const [idDistrict, setIdDistrict] = useState<string[]>(DistrictId || []);

	const handleRegion = (value: TSelectField[]) => {
		setIdRegions(value.map(({ id }) => id));
		setRegion(value);
	};

	const handleDistrict = (value: TSelectField[]) => {
		setIdDistrict(value.map(({ id }) => id));
		setDistrict(value);
	};

	useEffect(() => {
		if (!isEmpty) return;

		setIdRegions([]);
		setIdDistrict([]);
	}, [isEmpty]);

	useEffect(() => {
		setIdDistrict([]);
	}, [JSON.stringify(RegionIDS)]);

	return (
		<Fragment>
			<div className={classNames(styles.item)}>
				<span className={styles.item_label}>{t('filters.orderComp.region')}</span>

				<InputRegions
					RegionId={RegionIDS}
					DistrictId={DistrictIDS}
					onChange={handleRegion}
					onResultBlock={onResultBlock}
					onSetHidden={onSetHidden}
					text={t('filters.orderComp.choice')}
					prop="RegionId"
				/>
			</div>

			{idRegion.length !== 0 && (
				<div className={classNames(styles.item)}>
					<span className={styles.item_label}>Район</span>
					<InputDistricts id={idRegion} onChange={handleDistrict} onResultBlock={onResultBlock} />
				</div>
			)}

			{idDistrict.length !== 0 && (
				<div className={classNames(styles.item)}>
					<span className={styles.item_label}>Город</span>
					<InputCities DistrictId={idDistrict} RegionId={idRegion} onChange={setCities} onResultBlock={onResultBlock} />
				</div>
			)}
		</Fragment>
	);
};
