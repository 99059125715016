import React, { FC, useState } from 'react';
import styles from './month.module.scss';
import { ReactComponent as ArrowSvg } from 'assets/images/downTimes/arrow.svg';
import classNames from 'classnames';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import { List } from '../List';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';

type TButtonProps = {
	text: string | number;
	onClick: () => void;
	isOpen: boolean;
};
const Button: FC<TButtonProps> = ({ text, onClick, isOpen }) => {
	return (
		<div className={classNames(styles.month, { [styles.month_open]: isOpen })} onClick={onClick}>
			<div className={styles.month_text}>{text}</div>

			<div className={styles.month_arrow}>
				<ArrowSvg />
			</div>
		</div>
	);
};
export const MonthPanel = () => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const { month, mode, year } = useSelector((state: IStoreState) => state.downTimes);
	const isMonth = mode === 'month';

	return (
		<OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
			<div className={styles.panel}>
				<Button text={isMonth ? t(month.name) : year} onClick={() => setIsOpen(!isOpen)} isOpen={isOpen} />

				{isOpen && (
					<div className={styles.panel_list}>
						<List onClose={() => setIsOpen(false)} />
					</div>
				)}
			</div>
		</OutsideClickHandler>
	);
};
