export const title = {
	confirm: 'actions.confirmDeliveries',
	cancel: 'actions.cancelDeliveries',
	export: 'actions.exportOrders',
};

export const helps = {
	confirm: 'actions.confirmDeliveriesText',
	cancel: 'actions.cancelDeliveriesText',
	export: '',
};

export const description = {
	confirm: 'actions.confirmDeliveriesLong',
	cancel: 'actions.cancelDeliveriesLong',
	export: 'actions.exportOrdersText',
};

export const text = {
	confirm: 'actions.confirm',
	cancel: 'actions.yes',
	export: 'actions.continue',
};

export const ACTIONS = [{ id: 'confirm' }, { id: 'cancel' }, { id: 'export' }];
