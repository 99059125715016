import React, { useMemo } from 'react';
import { ReactComponent as HomeSVG } from 'assets/images/home.svg';
import { ReactComponent as ArchiveSVG } from 'assets/images/events/archive.svg';
import { DropButton } from 'components/Common/DropButton';
import styles from './units.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { fetchOrderBuyGroup, setCurrent } from 'services/store/orders';
import {
	defaultListEvents,
	defaultListMessages,
	defaultListOrders,
	setFiltersEvents,
	setFiltersMessages,
} from 'services/store/filter';
import { IStoreState, TFilterOrders } from 'services/store/interfaces';
import { useHistory } from 'react-router';

interface IAllUnitsProps {
	type?: 'orders' | 'goods' | 'events' | 'order' | 'multiOrders' | 'notifications' | 'divide';
}

export const Units = ({ type = 'goods' }: IAllUnitsProps) => {
	const dispatcher = useDispatch();
	const history = useHistory();
	const ordersFilter = useSelector((state: IStoreState) => state.filter.listOrders);

	const isEvents = type === 'events';
	const isOrders = type === 'orders';
	const isMultiEdit = type === 'multiOrders';
	const isOrder = type === 'order';

	const icon = isEvents ? <ArchiveSVG /> : <HomeSVG />;

	const setDefaultMode = (filters: TFilterOrders) => {
		dispatcher(setCurrent({ id: '0' }));
		dispatcher(fetchOrderBuyGroup({ ...filters, IsGrouping: '0' }));
		history.push('/orders');
	};

	const setDefaultValue = useMemo(
		() => ({
			events: () => dispatcher(setFiltersEvents({ filters: defaultListEvents })),
			notifications: () => dispatcher(setFiltersMessages({ filters: defaultListMessages })),
			multiOrders: () => setDefaultMode(defaultListOrders),
			divide: () => setDefaultMode(defaultListOrders),
			order: () => setDefaultMode(ordersFilter),
			orders: () => {},
			goods: () => {},
		}),
		[ordersFilter]
	);

	return (
		<DropButton
			text={''}
			icon={icon}
			mix={classNames(styles.units, {
				[styles.units_active]: isOrders,
				[styles.units_disabled]: !isMultiEdit && !isOrder,
			})}
			onClick={setDefaultValue[type]}
		/>
	);
};
