import React from 'react';
import classNames from 'classnames';
import styles from './status.module.scss';
import { TCurrentOrder } from 'services/store/interfaces';
import { getOrderStatus } from 'services/utils/js/getOrderStatus';
import { Loader } from '../Loading/Loader';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface IStatusProps {
	order: TCurrentOrder;
	type?: string;
}
export const Status = ({ order, type }: IStatusProps) => {
	const { t } = useTranslation();
	const status = getOrderStatus(order);

	return status && t(status.text) !== '' ? (
		<motion.div
			animate={{ width: 'auto' }}
			transition={{ duration: 0.5 }}
			className={classNames(styles.status, {
				[styles.detail]: type === 'detail',
				[styles.multiEdit]: type === 'multiEdit',
				[styles.error]: status.type === 'error',
				[styles.warning]: status.type === 'warning',
				[styles.default]: status.type === 'default',
			})}
		>
			{t(status.text)}
		</motion.div>
	) : (
		<Loader />
	);
};
