import React from 'react';
import styles from './messagesMenu.module.scss';
import { useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { Message } from './Message';
import { useTranslation } from 'react-i18next';

export const MessagesMenu = () => {
	const { t } = useTranslation();

	const count = useSelector((state: IStoreState) => state.notifications.sidebarUnreadCount);
	const notifications = useSelector((state: IStoreState) => state.notifications.sidebarUnreadList);

	return (
		<div className={styles.messagesMenu}>
			<div className={styles.header}>
				<div className={styles.title}>{t('rightMenu.notifications')}</div>
				<div className={styles.count}>{count}</div>
			</div>

			<div className={styles.list}>
				{count > 0 &&
					notifications.map(({ MessageId = '', Timex = '', MessageText = '', MessageTypeId = '' }, index) => {
						return (
							<Message
								key={index}
								MessageId={MessageId}
								Timex={Timex}
								MessageText={MessageText}
								MessageTypeId={MessageTypeId}
							/>
						);
					})}

				{/*<div*/}
				{/*	className={classNames(styles.event_more, {*/}
				{/*		[styles.event_more__hidden]: curPage >= pageQty,*/}
				{/*	})}*/}
				{/*	onClick={moreClick}*/}
				{/*>*/}
				{/*	Загрузить еще*/}
				{/*</div>*/}
			</div>
		</div>
	);
};
