import React, { Fragment } from 'react';
import { setMessage } from 'services/store/divideOrders';
import { ActionButton } from 'components/Common/ActionButton';
import { useDispatch } from 'react-redux';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';

export const DivideAction = ({ disabled }: { disabled?: boolean }) => {
	const { t } = useTranslation();
	const breakpoints = useBreakpoint();
	const dispatch = useDispatch();
	const isMobile = ['xs'].includes(breakpoints);

	return (
		<Fragment>
			<ActionButton
				type="separate"
				text={isMobile ? 'operations.dividePut' : 'operations.divide'}
				onClick={() =>
					dispatch(
						setMessage({
							title: t('multiDivide.yourDivide'),
							text: t('multiDivide.yourDivideLong'),
							type: 'divide',
						})
					)
				}
				disabled={disabled}
			/>
		</Fragment>
	);
};
