import React, { memo } from 'react';
import HeaderWithAuth from './HeaderWithAuth';
import HeaderWithoutAuth from './HeaderWithoutAuth';
import { useAuthorised } from 'services/hooks/useAuthorised';

export const Header = memo(() => {
	const isAuthorized = useAuthorised();

	return isAuthorized ? <HeaderWithAuth /> : <HeaderWithoutAuth />;
});
