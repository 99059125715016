import React, { FC } from 'react';
import styles from './Button.module.scss';
import { ReactComponent as ArrowSVG } from 'assets/images/actions/arrow_down.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type TButtonProps = {
	onClick: () => void;
	isOpen: boolean;
};
export const Button: FC<TButtonProps> = ({ onClick, isOpen }) => {
	const { t } = useTranslation();

	return (
		<div
			className={classNames(styles.button, {
				[styles.button_opened]: isOpen,
			})}
			onClick={onClick}
		>
			<div className={styles.button_text}>{t('orders.operations')}</div>

			<div
				className={classNames(styles.button_arrow, {
					[styles.button_arrow__isClose]: isOpen,
				})}
			>
				<ArrowSVG />
			</div>
		</div>
	);
};
