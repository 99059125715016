import { TCheckX, TCheckXResponse } from 'services/store/interfaces';

export const getCheckxArr = (obj: TCheckX): TCheckXResponse[] => {
	let arr: TCheckXResponse[] = [];

	for (const prop in obj) {
		arr = [...arr, { text: obj[prop], id: prop }];
	}

	return arr;
};
