import React, { FC } from 'react';
import styles from './oferta.module.scss';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const Oferta: FC = () => {
	const { t } = useTranslation();
	const { isPlanningTable } = useSelector((state: IStoreState) => state.reports);

	return (
		<div
			className={classNames(styles.public, {
				[styles.public_schedule]: !isPlanningTable,
			})}
		>
			<div className={styles.public_title}>{t('oferta.public')}</div>

			<div className={styles.public_text}>{t('oferta.publicLong')}</div>
		</div>
	);
};
