import React, { memo } from 'react';
import styles from './currentPanel.module.scss';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const CurrentPanel = memo(() => {
	const { t } = useTranslation();
	const { year, month, mode } = useSelector((state: IStoreState) => state.downTimes);
	const isMonth = mode === 'month';

	return (
		<div className={styles.current}>
			{isMonth && <div className={styles.current_month}>{t(month.name)}</div>}
			<div className={styles.current_year}>{year}</div>
		</div>
	);
});
