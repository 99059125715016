import React, { FC, useState, useEffect } from 'react';
import styles from './month.module.scss';
import { DAYS } from '../../../DatePanel/Month/data';
import { TDownTimesYearData, TMonth, TNumDay, TNumRequired } from 'services/store/interfaces/downTimes';
import { useDispatch } from 'react-redux';
import { getWeeksInMonth } from 'services/utils/helpers/dateDownTimes';
import classNames from 'classnames';
import { setCurrentMonth } from 'services/store/downTimes';
import { useTranslation } from 'react-i18next';

type TMonthYearProps = {
	month: TMonth;
	year: number;
	yearData: TDownTimesYearData[];
};

const getIsToday = (day: TNumDay | TNumRequired) => {
	const dToday = new Date().getDate();
	const mToday = new Date().getMonth();
	const yToday = new Date().getFullYear();

	return day ? dToday === day.num && mToday === day.month && yToday === day.year : false;
};

export const Month: FC<TMonthYearProps> = ({ month, year, yearData }) => {
	const { t } = useTranslation();
	const apiData = yearData.length > 0 ? yearData.filter(({ Monthx }) => Monthx === month.id)[0]?.Days : [];
	const weeks = getWeeksInMonth(year, month.id, apiData || []);
	const dispatch = useDispatch();

	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setTimeout(() => setIsVisible(true), 300);
	}, [JSON.stringify(yearData)]);

	return (
		<div
			className={styles.month}
			onClick={() => {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				dispatch(setCurrentMonth({ month, mode: 'month' }));
			}}
		>
			<div className={styles.month_title}>{t(month.name)}</div>

			<div className={styles.month_yearDaysName}>
				{DAYS.map((name, inx) => (
					<div key={inx} className={styles.month_yearDaysName__item}>
						{t(name)}
					</div>
				))}
			</div>

			<div className={styles.month_yearDays}>
				{weeks.map((week, inx) => (
					<div key={inx} className={styles.week}>
						{week.map((day, inx) => (
							<div
								className={classNames(styles.week_day, {
									[styles.week_day__downTime]: day.DayId,
									[styles.week_day__today]: getIsToday(day) && !day.DayId,
									[styles.week_day__past]: month.id !== day.month && !day.DayId,
									[styles.week_day__borderLeft]: day.DayId && !week[inx - 1]?.DayId,
									[styles.week_day__borderRight]: day.DayId && !week[inx + 1]?.DayId,
								})}
								key={day.num}
								style={day.DayId ? (isVisible ? { opacity: 1 } : { opacity: 0 }) : {}}
							>
								{day.num}
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};
