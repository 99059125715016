import React from 'react';
import styles from './homeDocs.module.scss';
import { Doc } from './Doc';
import { useTranslation } from 'react-i18next';

const docs = [
	{
		id: '1',
		title: 'home.use',
		text: 'home.pdfRec',
		img: 'pdf.svg',
		link: `/PDFUser.pdf?=${new Date().getTime()}`,
	},
];

export const HomeDocs = () => {
	const { t } = useTranslation();
	const src = '/assets/images/homepage/';

	return (
		<div className={styles.homeDocs}>
			{docs.map(({ id, title, text, img, link }) => (
				<Doc key={id} title={t(title)} text={t(text)} img={`${src}${img}`} link={link} />
			))}
		</div>
	);
};
