import React, { useState } from 'react';
import styles from './editUnits.module.scss';
import { ReactComponent as Close } from 'assets/images/actions/close.svg';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setCurrent } from 'services/store/orders';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface IEditUnits {
	type: 'goods' | 'orders' | 'events' | 'order' | 'multiOrders' | 'notifications' | 'divide';
}
export const EditUnits = ({ type }: IEditUnits) => {
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState(true);
	const isOrders = type === 'orders';
	const isMulti = type === 'multiOrders';

	const dispatch = useDispatch();
	const handleClick = () => dispatch(setCurrent({ id: 'edit' }));
	const handleHide = () => {
		if (isOrders) {
			setIsVisible(false);
		}

		if (isMulti) {
			dispatch(setCurrent({ id: '0' }));
		}
	};

	return (
		<AnimatePresence>
			<motion.div
				initial={{ opacity: 0, x: 20 }}
				animate={{ opacity: 1, x: 0 }}
				exit={{ opacity: 0, x: 20 }}
				transition={{ duration: 0.5 }}
				className={classNames(styles.edit, { [styles.edit__hide]: !isVisible, [styles.edit__active]: isMulti })}
			>
				<span className={styles.edit__number} onClick={handleClick}>
					<Link to="/orders/edit">{t('actions.multiEdit')}</Link>
				</span>

				<span
					className={classNames(styles.edit__close, { [styles.edit__close__active]: isMulti })}
					onClick={handleHide}
				>
					<Close />
				</span>
			</motion.div>
		</AnimatePresence>
	);
};
