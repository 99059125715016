import React, { Fragment } from 'react';
import styles from '../infoTable.module.scss';
import { InputCheckbox } from 'components/Common/Input/InputCheckbox';
import { IStoreState, TGetContractorGoodsResponse } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getLicense } from 'services/utils/js/getGoodLicense';
import { useTranslation } from 'react-i18next';

interface IInfoGoodsProps {
	onChange: (value: boolean) => (value: string) => void;
}
export const InfoGoods = ({ onChange }: IInfoGoodsProps) => {
	const { t } = useTranslation();
	const goodsList = useSelector((state: IStoreState) => state.goods.goodsList);
	const activeGoodsList = useSelector((state: IStoreState) => state.goods.activeGoods);
	const settingGoods = useSelector((state: IStoreState) => state.setting.listGoods);

	return (
		<Fragment>
			{goodsList.map((good, index) => {
				return (
					<tr
						className={classNames(styles.tr, {
							[styles.active]: activeGoodsList?.includes(good.Id),
						})}
						key={index}
					>
						<td className={styles.td}>
							<InputCheckbox
								isActive={activeGoodsList?.includes(good.Id)}
								onChange={(isActive) => onChange(isActive)(good.Id)}
							/>
						</td>

						{settingGoods.map(({ id, isShow }) => {
							const isLicense = id === 'LicenseType';
							const value = good[id as keyof TGetContractorGoodsResponse];

							return isShow ? (
								<td
									className={classNames(styles.td, {
										[styles.td__default]: isLicense && getLicense(value as string).id === '0',
										[styles.td__tabak]: isLicense && getLicense(value as string).id === '1',
										[styles.td__alcohol]: isLicense && getLicense(value as string).id === '2',
									})}
									key={id}
								>
									{isLicense ? t(getLicense(value as string).value) : value}
								</td>
							) : null;
						})}
					</tr>
				);
			})}
		</Fragment>
	);
};
