const getCountDays = (month: number, year: number) => {
	return 33 - new Date(year, month, 33).getDate();
};
const getDateNow = () => {
	const dateNow = new Date();
	const yearNow = dateNow.getFullYear();
	const monthNow = dateNow.getMonth();
	const dayNow = dateNow.getDate();

	return { yearNow, monthNow, dayNow };
};

export const getCurrentDate = () => {
	const { yearNow, monthNow, dayNow } = getDateNow();

	const month = monthNow + 1 < 10 ? `0${monthNow + 1}` : monthNow + 1;
	const day = dayNow < 10 ? `0${dayNow}` : dayNow;

	return `${day}/${month}/${yearNow}`;
};
export const getDateStart = (countMonth = 2): string => {
	const { yearNow, monthNow, dayNow } = getDateNow();

	const isYearStart = monthNow + 1 - countMonth < 0 || monthNow + 1 - countMonth === 0;

	const year = isYearStart ? yearNow - 1 : yearNow;
	const month = monthNow - countMonth < 0 ? 12 + (monthNow - countMonth) + 1 : monthNow + 1 - countMonth;
	const day = dayNow === 30 || dayNow === 31 ? getCountDays(month - 1, year) : dayNow;

	return `${year}-${month}-${day}`;
};

export const getDateEnd = (countMonth = 1): string => {
	const { yearNow, monthNow, dayNow } = getDateNow();

	const months = monthNow + 1 + countMonth;
	const year = months > 12 ? yearNow + 1 : yearNow;
	const monthNum = months > 12 ? 0 : months;
	const month = monthNum < 10 ? `0${months}` : months;

	const day = dayNow === 30 || dayNow === 31 ? getCountDays(monthNum, year) : dayNow;
	return `${year}-${month}-${day}`;
};

export const getDateFilter = (date: string) => {
	const isPoint = date.includes('.');
	const sign = isPoint ? '.' : '-';

	if (isPoint) {
		const [day, month, year] = date.split(sign);
		return `20${year}-${month}-${day}`;
	}

	const [year, month, day] = date.split(sign);
	return `${year}-${month}-${day}`;
};

export const getDateMountedFilter = (date: string) => {
	const [year, month, day] = date.split('-');

	return `${day}.${month}.${year.slice(-2)}`;
};
