import React, { Fragment, useEffect } from 'react';
import { OrdersTable } from './OrdersTable';
import { Pagination } from 'components/Common/Pagination';
import { fill } from 'services/utils/js/fill';
import { FilterList } from 'components/Filter/FilterList';
import { Mobile } from 'components/HOCS/Mobile';
import { SettingList } from 'components/Settings/SettingList';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { Loading } from 'components/Common/Loading';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { Message } from 'components/Common/Message';
import { fetchOrderBuyGroupWithMessages } from 'services/store/orders';
import { fetchOrderBuyGroupWithGroup } from 'services/store/grouping';
import { useLanguage } from 'services/contexts/languageContext';

export const OrdersGroup = () => {
	const language = useLanguage();

	const isFilter = useSelector((state: IStoreState) => state.filter.isShowOrders);
	const isSettings = useSelector((state: IStoreState) => state.setting.isShowOrders);
	const isLoadingOrders = useSelector((state: IStoreState) => state.orders.isLoading);
	const GroupParamLevel = useSelector(
		(state: IStoreState) => state.grouping.groupingProps,
		(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
	);

	const { isStartGroup } = useSelector((state: IStoreState) => state.grouping);

	const message = useSelector(
		(state: IStoreState) => state.orders.message,
		(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
	);
	const filters = useSelector((state: IStoreState) => state.filter.listOrders);

	const items = useSelector((state: IStoreState) => state.orders.count);
	const orders = useSelector(
		(state: IStoreState) => state.orders.ordersList,
		(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
	);
	const limit = useSelector((state: IStoreState) => state.filter.listOrders.Limit);
	const pages = fill(Math.ceil(items / (limit || 30)));

	const dispatch = useDispatch();
	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');
	const isActions = isFilter || isSettings;
	const isShowOrdersGroups = isMobile ? !isActions : !isMobile;
	const isShowMessage = !isActions && orders.length === 0;

	useEffect(() => {
		if (GroupParamLevel.length > 0)
			dispatch(
				fetchOrderBuyGroupWithGroup({
					...filters,
					IsGrouping: '1',
					GroupParamLevel: GroupParamLevel.map(({ id }) => id),
					Language: language === 'ru' ? 1 : 2,
				})
			);

		if (GroupParamLevel.length < 1)
			dispatch(
				fetchOrderBuyGroupWithMessages({
					...filters,
					IsGrouping: '0',
					GroupParamLevel: [],
					Language: language === 'ru' ? 1 : 2,
				})
			);
	}, [filters]);

	useEffect(() => {
		if (!(GroupParamLevel.length > 0 && isStartGroup)) return;

		dispatch(
			fetchOrderBuyGroupWithGroup({
				...filters,
				IsGrouping: '1',
				GroupParamLevel: GroupParamLevel.map(({ id }) => id),
				Language: language === 'ru' ? 1 : 2,
			})
		);
	}, [JSON.stringify(GroupParamLevel), isStartGroup]);

	return isLoadingOrders ? (
		<Loading />
	) : (
		<Fragment>
			<Mobile>
				{isFilter && <FilterList type="orders" />}
				{isSettings && <SettingList type="orders" />}
				{isShowMessage && <Message text={message} />}
			</Mobile>

			{isShowOrdersGroups && (
				<Fragment>
					<OrdersTable />

					{orders.length > 0 && <Pagination pages={pages} items={items} />}
				</Fragment>
			)}
		</Fragment>
	);
};
