import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getJWT } from 'services/api/getJWT';
import { IGetJWTPayload } from 'services/api/getJWT/interfaces';
import { IState, TCleanTokenPayload } from './interfaces';
import { cleanToken } from '../api/cleanToken';

const initialState: IState = {
	value: null,
	error: '',
	loading: false,
};

export const fetchJWT = createAsyncThunk('jwt/fetchJWT', async (data: IGetJWTPayload) => {
	return await getJWT(data);
});

export const fetchCleanToken = createAsyncThunk('jwt/fetchCleanToken', async (data: TCleanTokenPayload) => {
	return await cleanToken(data);
});

const jwt = createSlice({
	name: 'jwt',
	initialState,
	reducers: {
		setToken: (state, action) => {
			if (action.payload) state.value = action.payload;
		},
		setSuperToken: (state, action) => {
			state.value = action.payload;
			localStorage.setItem('token', action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchJWT.pending, (state) => {
			state.loading = true;
			state.error = '';
		});
		builder.addCase(fetchJWT.fulfilled, (state, action) => {
			const result = action.payload[0];

			if (result.JWT) {
				state.value = result.JWT;
				localStorage.setItem('token', result.JWT);
				state.loading = false;

				return;
			}

			if (result.ErrorDescription) {
				state.value = null;
				state.error = 'home.error';
				state.loading = false;

				return;
			}

			return state;
		});
		builder.addCase(fetchJWT.rejected, () => {});
		builder.addCase(fetchCleanToken.pending, () => {});
		builder.addCase(fetchCleanToken.fulfilled, (state, action) => {
			const res = action.payload[0];

			if (res.CountDeleted) {
				localStorage.removeItem('token');
				localStorage.removeItem('userId');
				localStorage.removeItem('userName');
				localStorage.removeItem('userIsAllowedDemandPlan');
				state.value = null;
				location.href = '/login';
			}
		});
		builder.addCase(fetchCleanToken.rejected, () => {});
	},
});

export const { setSuperToken } = jwt.actions;
export default jwt.reducer;
