import React from 'react';
import styles from './index.module.scss';
import { ReactComponent as LogoImg } from 'assets/images/header/logo.svg';
import { ReactComponent as LogoEng } from 'assets/images/header/logoEng.svg';
import { Callto } from '../Header/HeaderWithAuth/Callto';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLanguage } from 'services/contexts/languageContext';
import classNames from 'classnames';

export const Logo = () => {
	const lang = useLanguage();
	const { t } = useTranslation();

	return (
		<div className={styles.logo}>
			<Link to="/home">
				<div
					className={classNames(styles.logo_image, {
						[styles.logo_image__eng]: lang === 'en',
					})}
				>
					{lang === 'ru' ? <LogoImg /> : <LogoEng />}
				</div>
			</Link>

			<Link to="/home">
				<div className={styles.logo_text}>
					<span className={styles.logo_text__desktop}>{t('home.account')}</span>
					<span className={styles.logo_text__mobile}>{t('home.loginAccount')}</span>
				</div>
			</Link>

			<div className={styles.logo_phone}>
				<Callto type="email" email="zakaz_support@eurotorg.by">
					zakaz_support@eurotorg.by
				</Callto>
			</div>
		</div>
	);
};
