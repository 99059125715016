import { createSlice } from '@reduxjs/toolkit';
import { ISettingAction, IStateSettings } from './interfaces';
import { showGoodsFilter, showOrdersFilter, showOrderFilter } from './filter';
import { showEvents } from './events';
import { showNotification } from './notifications';

const listDefault = [
	{ text: '№', id: 'RowNumber', isShow: true, inSetting: true },
	{ text: 'goods.settings.GoodsId', id: 'GoodsId', isShow: true, inSetting: true },
	{ text: 'goods.settings.GoodsName', id: 'GoodsName', isShow: true, inSetting: true },
	{ text: 'order.settings.Barcode1', id: 'Barcode1', isShow: true, inSetting: true },
	{ text: 'goods.settings.QuantityInPack', id: 'QuantityInPack', isShow: true, inSetting: true },
	{ text: 'order.settings.QuantityOrder', id: 'QuantityOrder', isShow: true, inSetting: true },
	{ text: 'order.settings.QuantitySupplier', id: 'QuantitySupplier', isShow: true, inSetting: true },
	{ text: 'order.settings.QuantityAdd2', id: 'QuantityAdd2', isShow: true, inSetting: true },
	{ text: 'order.settings.Firstx', id: 'Firstx', isShow: true, inSetting: true },
	{ text: 'order.settings.Price', id: 'Price', isShow: true, inSetting: true },
	{ text: 'order.settings.DifQuantity', id: 'DifQuantity', isShow: false, inSetting: true },
	{ text: 'order.settings.Info', id: 'Info', isShow: false, inSetting: false },
	{ text: 'order.settings.IsPromo', id: 'IsPromo', isShow: false, inSetting: false },
	{ text: 'order.settings.PlanPromo', id: 'PlanPromo', isShow: false, inSetting: false },
];

const initialState: IStateSettings = {
	isShowGoods: false,
	isShowOrders: false,
	isShowOrder: false,
	isShowEvents: false,
	isShowMultiOrders: false,
	isShowDivide: false,
	isShowReportsAllGoods: false,
	isShowReportsQuality: false,
	isShowReportsCurrent: false,
	isShowReportsPlanning: false,
	listReportsPlanning: [
		{ text: 'goods.settings.GoodsId', id: 'ITEM_CODE', isShow: true, inSetting: false },
		{ text: 'goods.settings.GoodsName', id: 'ITEM_DESCR', isShow: true, inSetting: true },
		{ text: 'reports.plannedQuantity', id: 'ORDER_QTY', isShow: true, inSetting: true },
		{ text: 'reports.weekOfYear', id: 'WEEK_DATEX', isShow: true, inSetting: true },
	],
	listReportsAllGoods: [
		{ text: 'goods.settings.GoodsId', id: 'GoodsId', isShow: true, inSetting: false },
		{ text: 'goods.settings.GoodsName', id: 'GoodsName', isShow: true, inSetting: false },
		{ text: 'order.settings.Barcode1', id: 'Barcode1', isShow: true, inSetting: true },
		{ text: 'order.settings.QuantityInPackShort', id: 'QuantityInPack', isShow: true, inSetting: true },
		{ text: 'order.settings.QuantityOrderShort', id: 'QuantityAdd2', isShow: true, inSetting: true },
		{ text: 'reports.agreedQuantity', id: 'QuantityOrder', isShow: true, inSetting: true },
		{ text: 'reports.numberOrders', id: 'CountOrders', isShow: true, inSetting: true },
	],
	listReportsQuality: [
		{ text: 'reports.orderDate', id: 'TTTime', isShow: true, inSetting: true },
		{ text: 'orders.number', id: 'OrderBuyGroupId', isShow: true, inSetting: false },
		{ text: 'reports.percent', id: 'PercentAgreed', isShow: true, inSetting: true },
		{ text: 'reports.plannedDate', id: 'DatexPlan', isShow: true, inSetting: false },
		{ text: 'reports.factDate', id: 'DatexFact', isShow: true, inSetting: false },
		{ text: 'reports.region', id: 'RegionName', isShow: true, inSetting: true },
		{ text: 'orders.settings.WarehouseIdShort', id: 'WarehouseId', isShow: true, inSetting: true },
	],
	listReportsCurrent: [
		{ text: 'SKU', id: 'GoodsId', isShow: true, inSetting: true },
		{ text: 'export.GoodsName', id: 'GoodsName', isShow: true, inSetting: false },
		{ text: 'export.Quantity', id: 'GoodsQuantity', isShow: true, inSetting: true },
		{ text: 'order.settings.Firstx', id: 'Firstx', isShow: true, inSetting: true },
		{ text: 'order.settings.Price2', id: 'Price2', isShow: true, inSetting: true },
	],
	listOrder: [
		{ text: 'order.settings.RowNumber', id: 'RowNumber', isShow: true, inSetting: true },
		{ text: 'order.settings.GoodsId', id: 'GoodsId', isShow: true, inSetting: false },
		{ text: 'order.settings.GoodsName', id: 'GoodsName', isShow: true, inSetting: false },
		{ text: 'order.settings.Barcode1', id: 'Barcode1', isShow: true, inSetting: true },
		{ text: 'order.settings.QuantityInPack', id: 'QuantityInPack', isShow: true, inSetting: true },
		{ text: 'order.settings.QuantityOrder', id: 'QuantityOrder', isShow: true, inSetting: true },
		{ text: 'order.settings.QuantitySupplier', id: 'QuantitySupplier', isShow: true, inSetting: true },
		{ text: 'order.settings.QuantityAdd2', id: 'QuantityAdd2', isShow: true, inSetting: true },
		{ text: 'order.settings.LimitQuantity', id: 'LimitQuantity', isShow: true, inSetting: true },
		{ text: 'order.settings.Firstx', id: 'Firstx', isShow: true, inSetting: true },
		{ text: 'order.settings.Price', id: 'Price', isShow: true, inSetting: true },
		{ text: 'order.settings.Price2', id: 'Price2', isShow: true, inSetting: true },
		{ text: 'order.settings.DifQuantity', id: 'DifQuantity', isShow: false, inSetting: false },
		{ text: 'order.settings.Info', id: 'Info', isShow: false, inSetting: false },
		{ text: 'order.settings.IsPromo', id: 'IsPromo', isShow: false, inSetting: false },
		{ text: 'order.settings.PlanPromo', id: 'PlanPromo', isShow: false, inSetting: false },
	],
	listMultiOrder: [
		{ text: '№', id: 'RowNumber', isShow: true, inSetting: true },
		{ text: 'goods.settings.GoodsId', id: 'GoodsId', isShow: true, inSetting: false },
		{ text: 'goods.settings.GoodsName', id: 'GoodsName', isShow: true, inSetting: false },
		{ text: 'order.settings.Barcode1', id: 'Barcode1', isShow: true, inSetting: true },
		{ text: 'order.settings.QuantityInPack', id: 'QuantityInPack', isShow: true, inSetting: true },
		{ text: 'order.settings.QuantityOrder', id: 'QuantityOrder', isShow: true, inSetting: true },
		{ text: 'order.settings.QuantitySupplier', id: 'QuantitySupplier', isShow: true, inSetting: true },
		{ text: 'order.settings.QuantityAdd2', id: 'QuantityAdd2', isShow: true, inSetting: true },
		{ text: 'order.settings.LimitQuantity', id: 'LimitQuantity', isShow: true, inSetting: true },
		{ text: 'order.settings.Firstx', id: 'Firstx', isShow: true, inSetting: true },
		{ text: 'order.settings.Price', id: 'Price', isShow: true, inSetting: true },
		{ text: 'order.settings.DifQuantity', id: 'DifQuantity', isShow: false, inSetting: false },
		{ text: 'order.settings.Info', id: 'Info', isShow: false, inSetting: true },
		{ text: 'order.settings.IsPromo', id: 'IsPromo', isShow: false, inSetting: false },
		{ text: 'order.settings.PlanPromo', id: 'PlanPromo', isShow: false, inSetting: false },
	],
	listDivide: listDefault,
	listGoods: [
		{ text: 'goods.settings.GoodsId', id: 'GoodsId', isShow: true, inSetting: false },
		{ text: 'goods.settings.GoodsName', id: 'GoodsName', isShow: true, inSetting: false },
		{ text: 'goods.settings.ExpireMax', id: 'ExpireMax', isShow: true, inSetting: true },
		{ text: 'goods.settings.Height', id: 'Height', isShow: true, inSetting: true },
		{ text: 'goods.settings.Width', id: 'Width', isShow: true, inSetting: true },
		{ text: 'goods.settings.Depth', id: 'Depth', isShow: true, inSetting: true },
		{ text: 'goods.settings.HeightPack', id: 'HeightPack', isShow: true, inSetting: true },
		{ text: 'goods.settings.WidthPack', id: 'WidthPack', isShow: true, inSetting: true },
		{ text: 'goods.settings.DepthPack', id: 'DepthPack', isShow: false, inSetting: false },
		{ text: 'goods.settings.NetWeight', id: 'NetWeight', isShow: true, inSetting: true },
		{ text: 'goods.settings.Weight', id: 'Weight', isShow: false, inSetting: true },
		{ text: 'goods.settings.NetValue', id: 'NetValue', isShow: true, inSetting: true },
		{ text: 'goods.settings.NetMeasureName', id: 'NetMeasureName', isShow: true, inSetting: true },
		{ text: 'goods.settings.MeasureName1', id: 'MeasureName1', isShow: true, inSetting: true },
		{ text: 'goods.settings.QuantityInPack', id: 'QuantityInPack', isShow: true, inSetting: true },
		{ text: 'goods.settings.QuantityPackInBox', id: 'QuantityPackInBox', isShow: true, inSetting: true },
		{ text: 'goods.settings.QuantityBoxInPallet', id: 'QuantityBoxInPallet', isShow: false, inSetting: false },
		{ text: 'goods.settings.QuantityInPallet', id: 'QuantityInPallet', isShow: false, inSetting: false },
		{ text: 'goods.settings.GoodsMinQuantity', id: 'GoodsMinQuantity', isShow: true, inSetting: true },
		{ text: 'goods.settings.DeliveryQuantityPackMin', id: 'DeliveryQuantityPackMin', isShow: false, inSetting: false },
		{ text: 'goods.settings.ControlWeight', id: 'ControlWeight', isShow: false, inSetting: false },
		{ text: 'goods.settings.GoodsBrandId', id: 'GoodsBrandId', isShow: true, inSetting: true },
		{ text: 'goods.settings.LicenseType', id: 'LicenseType', isShow: true, inSetting: false },
	],
	listOrders: [
		{ text: 'orders.settings.OrderBuyGroupId', id: 'OrderBuyGroupId', isShow: true, inSetting: false },
		{ text: 'orders.settings.TTTime', id: 'TTTime', isShow: true, inSetting: false },
		{ text: 'orders.settings.Datex', id: 'Datex', isShow: true, inSetting: true },
		{ text: 'orders.settings.ShipmentDateLast', id: 'ShipmentDateLast', isShow: false, inSetting: true },
		{ text: 'orders.settings.NextOrderDate', id: 'NextOrderDate', isShow: true, inSetting: true },
		{ text: 'orders.settings.Checkx', id: 'Checkx', isShow: true, inSetting: false },
		{ text: 'orders.settings.WarehouseId', id: 'WarehouseId', isShow: true, inSetting: true },
		{ text: 'orders.settings.GLNWarehouse', id: 'GLNWarehouse', isShow: false, inSetting: true },
		{ text: 'orders.settings.DepartmentAddress', id: 'DepartmentAddress', isShow: true, inSetting: true },
		{ text: 'orders.settings.OrderSum', id: 'OrderSum', isShow: true, inSetting: true },
	],
	listEvents: [
		{ text: 'events.settings.DayName', id: 'DayName', isShow: true, inSetting: false },
		{ text: 'events.settings.Info', id: 'Info', isShow: true, inSetting: false },
		{ text: 'events.settings.Datex', id: 'Datex', isShow: true, inSetting: true },
		{ text: 'events.settings.DateStart', id: 'DateStart', isShow: false, inSetting: false },
		{ text: 'events.settings.DateEnd', id: 'DateEnd', isShow: true, inSetting: false },
		{ text: 'events.settings.ЕventTypeId', id: 'ЕventTypeId', isShow: true, inSetting: false },
	],
};

const settings = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		showGoods: (state: IStateSettings) => {
			state.isShowGoods = !state.isShowGoods;
		},
		showReportsAllGoods: (state: IStateSettings) => {
			state.isShowReportsAllGoods = !state.isShowReportsAllGoods;
		},
		showReportsCurrent: (state: IStateSettings) => {
			state.isShowReportsCurrent = !state.isShowReportsCurrent;
		},
		showReportsQuality: (state: IStateSettings) => {
			state.isShowReportsQuality = !state.isShowReportsQuality;
		},
		showReportsPlanning: (state: IStateSettings) => {
			state.isShowReportsPlanning = !state.isShowReportsPlanning;
		},
		showOrders: (state: IStateSettings) => {
			state.isShowOrders = !state.isShowOrders;
		},
		showOrder: (state: IStateSettings) => {
			state.isShowOrder = !state.isShowOrder;
		},
		showMultiOrders: (state: IStateSettings) => {
			state.isShowMultiOrders = !state.isShowMultiOrders;
		},
		showDivide: (state: IStateSettings) => {
			state.isShowDivide = !state.isShowDivide;
		},
		hideDivide: (state: IStateSettings) => {
			state.isShowDivide = false;
		},
		hideGoods: (state: IStateSettings) => {
			state.isShowGoods = false;
		},
		hideReportsAllGoods: (state: IStateSettings) => {
			state.isShowReportsAllGoods = false;
		},
		hideReportsCurrent: (state: IStateSettings) => {
			state.isShowReportsCurrent = false;
		},
		hideReportsQuality: (state: IStateSettings) => {
			state.isShowReportsQuality = false;
		},
		hideReportsPlanning: (state: IStateSettings) => {
			state.isShowReportsPlanning = false;
		},
		hideOrders: (state: IStateSettings) => {
			state.isShowOrders = false;
		},
		hideOrder: (state: IStateSettings) => {
			state.isShowOrder = false;
		},
		hideMultiOrders: (state) => {
			state.isShowMultiOrders = false;
		},
		showEventsSetting: (state: IStateSettings) => {
			state.isShowEvents = !state.isShowEvents;
		},
		hideEvents: (state: IStateSettings) => {
			state.isShowEvents = false;
		},
		setReportsAllGoodsActive: (state: IStateSettings) => {
			state.listReportsAllGoods = state.listReportsAllGoods.map((setting) =>
				setting.inSetting ? { ...setting, isShow: true } : setting
			);
		},
		setReportsCurrentActive: (state: IStateSettings) => {
			state.listReportsCurrent = state.listReportsCurrent.map((setting) =>
				setting.inSetting ? { ...setting, isShow: true } : setting
			);
		},
		setReportsQualityActive: (state: IStateSettings) => {
			state.listReportsQuality = state.listReportsQuality.map((setting) =>
				setting.inSetting ? { ...setting, isShow: true } : setting
			);
		},
		setReportsPlanningActive: (state: IStateSettings) => {
			state.listReportsPlanning = state.listReportsPlanning.map((setting) =>
				setting.inSetting ? { ...setting, isShow: true } : setting
			);
		},
		setOrdersActive: (state: IStateSettings) => {
			state.listOrders = state.listOrders.map((setting) =>
				setting.inSetting ? { ...setting, isShow: true } : setting
			);
		},
		setDivideActive: (state: IStateSettings) => {
			state.listDivide = state.listDivide.map((setting) =>
				setting.inSetting ? { ...setting, isShow: true } : setting
			);
		},
		setMultiOrdersActive: (state: IStateSettings) => {
			state.listMultiOrder = state.listMultiOrder.map((setting) =>
				setting.inSetting ? { ...setting, isShow: true } : setting
			);
		},
		setOrderActive: (state: IStateSettings) => {
			state.listOrder = state.listOrder.map((setting) => (setting.inSetting ? { ...setting, isShow: true } : setting));
		},
		setEventsActive: (state: IStateSettings) => {
			state.listEvents = state.listEvents.map((setting) =>
				setting.inSetting ? { ...setting, isShow: true } : setting
			);
		},
		setGoodsActive: (state: IStateSettings) => {
			state.listGoods = state.listGoods.map((setting) => (setting.inSetting ? { ...setting, isShow: true } : setting));
		},
		setReportAllGoodsNotActive: (state: IStateSettings) => {
			state.listReportsAllGoods = state.listReportsAllGoods.map((setting) =>
				setting.inSetting ? { ...setting, isShow: false } : setting
			);
		},
		setReportsCurrentNotActive: (state: IStateSettings) => {
			state.listReportsCurrent = state.listReportsCurrent.map((setting) =>
				setting.inSetting ? { ...setting, isShow: false } : setting
			);
		},
		setReportsQualityNotActive: (state: IStateSettings) => {
			state.listReportsQuality = state.listReportsQuality.map((setting) =>
				setting.inSetting ? { ...setting, isShow: false } : setting
			);
		},
		setReportsPlanningNotActive: (state: IStateSettings) => {
			state.listReportsPlanning = state.listReportsPlanning.map((setting) =>
				setting.inSetting ? { ...setting, isShow: false } : setting
			);
		},
		setOrdersNotActive: (state: IStateSettings) => {
			state.listOrders = state.listOrders.map((setting) =>
				setting.inSetting ? { ...setting, isShow: false } : setting
			);
		},
		setDivideNotActive: (state: IStateSettings) => {
			state.listDivide = state.listDivide.map((setting) =>
				setting.inSetting ? { ...setting, isShow: false } : setting
			);
		},
		setOrderNotActive: (state: IStateSettings) => {
			state.listOrder = state.listOrder.map((setting) => (setting.inSetting ? { ...setting, isShow: false } : setting));
		},
		setEventsNotActive: (state: IStateSettings) => {
			state.listEvents = state.listEvents.map((setting) =>
				setting.inSetting ? { ...setting, isShow: false } : setting
			);
		},
		setGoodsNotActive: (state: IStateSettings) => {
			state.listGoods = state.listGoods.map((setting) => (setting.inSetting ? { ...setting, isShow: false } : setting));
		},
		setMultiOrdersNotActive: (state: IStateSettings) => {
			state.listMultiOrder = state.listMultiOrder.map((setting) =>
				setting.inSetting ? { ...setting, isShow: false } : setting
			);
		},
		setSettingsReportsAllGoods: (state: IStateSettings, action: ISettingAction) => {
			if (action.payload) {
				const filterReportsAllGoods = state.listReportsAllGoods.map((item) => {
					if (item.id === action.payload.id) item.isShow = !item.isShow;
					return item;
				});

				state.listReportsAllGoods = [...filterReportsAllGoods];
			}
		},
		setSettingsReportsCurrent: (state: IStateSettings, action: ISettingAction) => {
			if (action.payload) {
				const filterReportsCurrent = state.listReportsCurrent.map((item) => {
					if (item.id === action.payload.id) item.isShow = !item.isShow;
					return item;
				});

				state.listReportsCurrent = [...filterReportsCurrent];
			}
		},
		setSettingsReportsQuality: (state: IStateSettings, action: ISettingAction) => {
			if (action.payload) {
				const filterReportsQuality = state.listReportsQuality.map((item) => {
					if (item.id === action.payload.id) item.isShow = !item.isShow;
					return item;
				});

				state.listReportsQuality = [...filterReportsQuality];
			}
		},
		setSettingsReportsPlanning: (state: IStateSettings, action: ISettingAction) => {
			if (action.payload) {
				const filterReportsPlanning = state.listReportsPlanning.map((item) => {
					if (item.id === action.payload.id) item.isShow = !item.isShow;
					return item;
				});

				state.listReportsPlanning = [...filterReportsPlanning];
			}
		},
		setSettingsOrders: (state: IStateSettings, action: ISettingAction) => {
			if (action.payload) {
				const filterOrders = state.listOrders.map((order) => {
					if (order.id === action.payload.id) order.isShow = !order.isShow;
					return order;
				});

				state.listOrders = [...filterOrders];
			}
		},
		setMultiOrders: (state: IStateSettings, action) => {
			if (action.payload) {
				const filterOrders = state.listMultiOrder.map((order) => {
					if (order.id === action.payload.id) order.isShow = !order.isShow;
					return order;
				});

				state.listMultiOrder = [...filterOrders];
			}
		},
		setSettingsGoods: (state: IStateSettings, action: ISettingAction) => {
			if (action.payload) {
				const filterGoods = state.listGoods.map((good) => {
					if (good.id === action.payload.id) good.isShow = !good.isShow;
					return good;
				});

				state.listGoods = [...filterGoods];
			}
		},
		setSettingsOrder: (state: IStateSettings, action: ISettingAction) => {
			if (action.payload) {
				const filterItems = state.listOrder.map((item) => {
					if (item.id === action.payload.id) item.isShow = !item.isShow;
					return item;
				});

				state.listOrder = [...filterItems];
			}
		},
		setSettingsEvents: (state: IStateSettings, action: ISettingAction) => {
			if (action.payload) {
				const filterItems = state.listEvents.map((item) => {
					if (item.id === action.payload.id) item.isShow = !item.isShow;
					return item;
				});

				state.listEvents = [...filterItems];
			}
		},
		setSettingsDivide: (state: IStateSettings, action: ISettingAction) => {
			if (action.payload) {
				const filterItems = state.listDivide.map((item) => {
					if (item.id === action.payload.id) item.isShow = !item.isShow;
					return item;
				});

				state.listDivide = [...filterItems];
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(showOrdersFilter, (state: IStateSettings) => {
				state.isShowOrders = false;
				state.isShowOrder = false;
			})
			.addCase(showGoodsFilter, (state: IStateSettings) => {
				state.isShowGoods = false;
			})
			.addCase(showEvents, (state: IStateSettings) => {
				state.isShowEvents = false;
			})
			.addCase(showNotification, (state: IStateSettings) => {
				state.isShowEvents = false;
			})
			.addCase(showOrderFilter, (state: IStateSettings) => {
				state.isShowOrder = false;
			})
			.addCase(showReportsQuality, (state: IStateSettings) => {
				state.isShowReportsQuality = false;
			})
			.addCase(showReportsCurrent, (state) => {
				state.isShowReportsCurrent = false;
			})
			.addCase(showReportsAllGoods, (state) => {
				state.isShowReportsAllGoods = false;
			});
	},
});

export const {
	showGoods,
	showOrders,
	showOrder,
	showEventsSetting,
	showReportsAllGoods,
	showReportsCurrent,
	showReportsQuality,
	showReportsPlanning,
	hideOrders,
	hideOrder,
	hideGoods,
	hideEvents,
	hideReportsAllGoods,
	hideReportsCurrent,
	hideReportsQuality,
	hideReportsPlanning,
	setSettingsEvents,
	setSettingsOrders,
	setSettingsGoods,
	setSettingsOrder,
	setOrdersActive,
	setGoodsActive,
	setEventsActive,
	setOrderActive,
	setOrdersNotActive,
	setGoodsNotActive,
	setEventsNotActive,
	setOrderNotActive,
	setMultiOrdersActive,
	setMultiOrders,
	setReportsPlanningNotActive,
	setReportsQualityNotActive,
	setReportAllGoodsNotActive,
	setReportsAllGoodsActive,
	setReportsCurrentActive,
	setReportsPlanningActive,
	setReportsCurrentNotActive,
	setReportsQualityActive,
	setSettingsReportsAllGoods,
	setSettingsReportsCurrent,
	setSettingsReportsQuality,
	setSettingsReportsPlanning,
	hideMultiOrders,
	showMultiOrders,
	setMultiOrdersNotActive,
	showDivide,
	hideDivide,
	setDivideNotActive,
	setDivideActive,
	setSettingsDivide,
} = settings.actions;
export default settings.reducer;
