import React from 'react';
import styles from './content.module.scss';
import data from '../data.json';
import { cookies } from '../cookies';
import { ReactComponent as Chrome } from 'assets/images/cookies/chrome.svg';
import { ReactComponent as Explore } from 'assets/images/cookies/explore.svg';
import { ReactComponent as Mozilla } from 'assets/images/cookies/firefox.svg';
import { ReactComponent as Safari } from 'assets/images/cookies/safari.svg';
import { ReactComponent as Opera } from 'assets/images/cookies/opera.svg';
import { ReactComponent as Edge } from 'assets/images/cookies/edge.svg';
import { Actions } from '../Actions';
import { useTranslation } from 'react-i18next';

const LINKS = [
	{
		id: 'Mozilla',
		img: <Mozilla />,
		link: 'https://support.mozilla.org/ru/kb/udalenie-kuki-i-dannyh-sajtov-v-firefox?redirectslug=udalenie-kukov-dlya-udaleniya-informacii-kotoruyu-&redirectlocale=ru',
	},
	{ id: 'Chrome', img: <Chrome />, link: 'https://support.google.com/chrome/answer/95647?hl=ru' },
	{ id: 'Safari', img: <Safari />, link: 'https://support.apple.com/ru-ru/guide/safari/sfri11471/mac' },
	{
		id: 'Opera',
		img: <Opera />,
		link: 'https://help.opera.com/ru/latest/web-preferences/#%D0%A3%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D1%84%D0%B0%D0%B9%D0%BB%D0%B0%D0%BC%D0%B8-cookie',
	},
	{
		id: 'Edge',
		img: <Edge />,
		link: 'https://support.microsoft.com/ru-ru/microsoft-edge/%D1%83%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D1%84%D0%B0%D0%B9%D0%BB%D0%BE%D0%B2-cookie-%D0%B2-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09',
	},
	{
		id: 'Explore',
		img: <Explore />,
		link: 'https://support.microsoft.com/ru-ru/windows/%D1%83%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D1%84%D0%B0%D0%B9%D0%BB%D0%BE%D0%B2-cookie-%D0%B8-%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B8%D0%BC%D0%B8-168dab11-0753-043d-7c16-ede5947fc64d',
	},
];

export const Cookies = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.cookies}>
			<div className={styles.cookies_header}>{t(data.header).toUpperCase()}</div>
			<div className={styles.cookies_content} dangerouslySetInnerHTML={{ __html: cookies }} />

			<div className={styles.cookies_links}>
				{LINKS.map(({ id, img, link }) => (
					<div key={id} className={styles.cookies_link}>
						<a href={link} target="_blank" rel="noreferrer">
							{img}
						</a>
					</div>
				))}
			</div>

			<Actions />
		</div>
	);
};
