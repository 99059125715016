import { TMonth } from 'services/store/interfaces/downTimes';

export const YEARS: number[] = [new Date().getFullYear() + 1, new Date().getFullYear(), new Date().getFullYear() - 1];
export const DAYS: string[] = ['days.Mon', 'days.Tue', 'days.Wed', 'days.Thu', 'days.Fri', 'days.Sat', 'days.Sun'];
export const DAYSRU: string[] = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const MONTHS: TMonth[] = [
	{ id: 0, name: 'months.january' },
	{ id: 1, name: 'months.february' },
	{ id: 2, name: 'months.march' },
	{ id: 3, name: 'months.april' },
	{ id: 4, name: 'months.may' },
	{ id: 5, name: 'months.june' },
	{ id: 6, name: 'months.july' },
	{ id: 7, name: 'months.august' },
	{ id: 8, name: 'months.september' },
	{ id: 9, name: 'months.october' },
	{ id: 10, name: 'months.november' },
	{ id: 11, name: 'months.december' },
];
