import React from 'react';
import styles from './message.module.scss';
import { useTranslation } from 'react-i18next';

interface IMessageProps {
	text: string;
}
export const Message = ({ text }: IMessageProps) => {
	const { t } = useTranslation();
	return <div className={styles.message}>{t(text)}</div>;
};
