import React from 'react';
import { setCurrent } from 'services/store/orders';
import { ActionButton } from 'components/Common/ActionButton';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ActionEditOrders = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<Link to="/orders/edit">
			<ActionButton text={t('actions.edit')} type="edit" onClick={() => dispatch(setCurrent({ id: '1' }))} />
		</Link>
	);
};
