import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IEventsAction, IEventsListState, TFilterEvents } from './interfaces';
import { showNotification } from './notifications';
import { showEventsSetting } from './setting';
import { getCalendarEvents } from '../api/getCalendarEvents';
import { setFiltersEvents } from './filter';

export const fetchGetCalendarEvents = createAsyncThunk('events/fetchGetCalendarEvents', async (data: TFilterEvents) => {
	return await getCalendarEvents(data);
});

const initialState: IEventsListState = {
	isShowEvents: true,
	current: '0',
	activeEvents: [],
	isLoading: false,
	message: '',
	clipboard: { text: '' },
	events: [],
	count: 0,
	pageQty: 0,
	type: 'events',
};

const events = createSlice({
	name: 'events',
	initialState,
	reducers: {
		addEvent: (state: IEventsListState, action: IEventsAction) => {
			if (action.payload.id) state.activeEvents = [...state.activeEvents, action.payload.id];
			return state;
		},
		removeEvent: (state: IEventsListState, action: IEventsAction) => {
			state.activeEvents = state.activeEvents.filter((id: string) => id !== action.payload.id);
			return state;
		},
		showEvents: (state: IEventsListState) => {
			state.isShowEvents = !state.isShowEvents;
		},
		hideEvents: (state: IEventsListState) => {
			state.isShowEvents = false;
		},
		setClipboard: (state, action) => {
			state.clipboard = { ...state.clipboard, ...action.payload };
		},
		setCurrentEvent: (state, action) => {
			state.current = action.payload;
		},
		setType: (state, action) => {
			state.type = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(showNotification, (state: IEventsListState) => {
			state.isShowEvents = false;
		});
		builder.addCase(showEventsSetting, (state: IEventsListState) => {
			if (window.innerWidth > 560) state.isShowEvents = false;
		});
		builder.addCase(fetchGetCalendarEvents.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(fetchGetCalendarEvents.fulfilled, (state: IEventsListState, action) => {
			const data = action.payload;
			state.isLoading = false;
			state.count = 0;

			if (data.length === 0) {
				state.message = 'multiDivide.emptyEvents';
				return;
			}

			if (data[0].Error) {
				state.message = 'multiDivide.error';
				return;
			}

			state.count = Number(data[0].Amount);
			state.pageQty = data[0].PageQty;
			state.events = data;
		});
		builder.addCase(fetchGetCalendarEvents.rejected, (state) => {
			state.count = 0;
			state.message = 'multiDivide.emptyEvents';
			state.isLoading = false;
		});
		builder.addCase(setFiltersEvents, (state) => {
			state.message = '';
		});
	},
});

export const { showEvents, setCurrentEvent, setType } = events.actions;
export default events.reducer;
