import React from 'react';
import styles from './addAction.module.scss';
import { Plus } from 'assets/images/downTimes/Plus';
import { useDispatch } from 'react-redux';
import { setIsOpenCreate } from 'services/store/downTimes';
import { useTranslation } from 'react-i18next';

export const AddAction = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const onCreate = () => dispatch(setIsOpenCreate(true));

	return (
		<div className={styles.addAction} onClick={onCreate}>
			<Plus width="16" height="16" color="black" />

			<div className={styles.addAction_text}>{t('events.add')}</div>
		</div>
	);
};
