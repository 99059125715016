import React from 'react';
import { ActionButton } from 'components/Common/ActionButton';
import { useDispatch } from 'react-redux';
import { setCurrent } from 'services/store/orders';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ActionSeparate = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<Link to="/orders/divide">
			<ActionButton
				text={t('operations.divide')}
				type="separate"
				onClick={() => dispatch(setCurrent({ id: 'divide' }))}
			/>
		</Link>
	);
};
