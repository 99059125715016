import React, { FC, ReactElement } from 'react';
import styles from './noResult.module.scss';
import { ReactComponent as EmptySVG } from 'assets/images/planning/empty.svg';
import { useTranslation } from 'react-i18next';

interface INoResultProps {
	text?: string;
	image?: ReactElement<SVGElement>;
}
export const NoResult: FC<INoResultProps> = ({ text = 'reports.noResult', image = <EmptySVG /> }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.noResult}>
			<div className={styles.noResult_image}>{image}</div>

			<div className={styles.noResult_text}>{`${t(text)}...`}</div>
		</div>
	);
};
