import React, { FC } from 'react';
import styles from './card.module.scss';
import { ReactComponent as EditSVG } from 'assets/images/downTimes/edit.svg';
import { ReactComponent as RemoveSVG } from 'assets/images/downTimes/trash.svg';
import classNames from 'classnames';
import { getDate, getIsFutureDown } from 'services/utils/helpers/dateDownTimes';
import { useTranslation } from 'react-i18next';

type TCardProps = {
	Datex: string | number | undefined;
	Reason: string | undefined;
	DateStart: string | undefined;
	DateEnd: string | undefined;
	Descript: string | undefined;
	DayId: string | undefined;
	onRemove: (id: string) => void;
	onEdit: () => void;
	isHidden?: boolean;
};
export const Card: FC<TCardProps> = ({ Datex, onRemove, onEdit, isHidden, ...props }) => {
	const { t } = useTranslation();

	return (
		<div
			className={classNames(styles.card, {
				[styles.card_hidden]: isHidden,
			})}
		>
			<div className={styles.card_title}>
				{t('events.downtime')}
				{Datex ? `, ${getDate(String(Datex))}` : ''}
			</div>

			{props.Reason && (
				<div className={styles.card_block}>
					<div className={styles.card_label}>{t('events.reason')}</div>
					<div className={styles.card_text}>{props.Reason}</div>
				</div>
			)}

			<div className={styles.card_block}>
				<div className={styles.card_label}>{t('events.downPeriod')}</div>
				<div className={styles.card_text}>
					{getDate(String(props.DateStart), true)} - {getDate(String(props.DateEnd), true)}
				</div>
			</div>

			{props.Descript && (
				<div className={styles.card_block}>
					<div className={styles.card_label}>{t('events.additional')}</div>
					<div className={styles.card_text}>{props.Descript}</div>
				</div>
			)}

			{getIsFutureDown(props.DateEnd) && (
				<div className={styles.card_actions}>
					<div className={styles.card_action} onClick={onEdit}>
						<div className={styles.card_icon}>
							<EditSVG />
						</div>

						<div className={styles.card_action__text}>{t('events.edit')}</div>
					</div>

					<div className={styles.card_action} onClick={() => onRemove(String(props.DayId))}>
						<div className={styles.card_icon}>
							<RemoveSVG />
						</div>
						<div className={styles.card_action__text}>{t('events.delete')}</div>
					</div>
				</div>
			)}
		</div>
	);
};
