import React, { FC, Fragment, useState, useEffect } from 'react';
import styles from './allGoods.module.scss';
import { IStoreState } from 'services/store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { clearReportsAllGoods, defaultListReportsAllGoods } from 'services/store/filter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import localeEn from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import { Actions } from 'components/Filter/Actions';
import { Button } from 'components/Filter/Actions/Button';
import classNames from 'classnames';
import { ReactComponent as Icon } from 'assets/images/filter/calendar.svg';
import { getDateFilter } from 'services/utils/js/getFilterDate';
import { TFilterOrdersProps } from '../types';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'services/contexts/languageContext';

dayjs.extend(customParseFormat);

export const FilterAllGoods: FC<TFilterOrdersProps> = ({ onChange = () => {}, onSet = () => {} }) => {
	const language = useLanguage();
	const { t } = useTranslation();
	const { listReportsAllGoods, isCleanReportsAllGoods, isShowReportsAllGoods } = useSelector(
		(state: IStoreState) => state.filter
	);

	const [fields, setFilters] = useState(isCleanReportsAllGoods ? defaultListReportsAllGoods : listReportsAllGoods);
	const [isChangeBegin, setClearBegin] = useState(false);
	const [isChangeEnd, setClearEnd] = useState(false);

	const dispatch = useDispatch();

	const onBegin = (value: unknown, dateString: string) => {
		setFilters({ ...fields, DateBegin: dateString });
		setClearBegin(true);
	};

	const onEnd = (value: unknown, dateString: string) => {
		const isEmptyBegin = fields.DateBegin === '';
		const isEmptyEnd = dateString === '';
		const DateEnd = isEmptyBegin ? '' : isEmptyEnd ? dayjs().toISOString().slice(0, 10) : dateString;

		setFilters({ ...fields, DateEnd });
		setClearEnd(true);
	};

	const onClear = () => {
		dispatch(clearReportsAllGoods());
		setFilters({ ...defaultListReportsAllGoods });
		setClearBegin(false);
		setClearEnd(false);
	};

	useEffect(() => {
		if (
			JSON.stringify(listReportsAllGoods) !==
			JSON.stringify({
				...fields,
				DateBegin: getDateFilter(String(fields.DateBegin)),
				DateEnd: getDateFilter(String(fields.DateEnd)),
			})
		)
			onClear();
	}, [isShowReportsAllGoods]);

	useEffect(() => {
		const DateBegin = getDateFilter(String(fields.DateBegin));
		const DateEnd = getDateFilter(String(fields.DateEnd));

		onChange({ ...fields, DateBegin, DateEnd });
	}, [JSON.stringify(fields)]);

	return (
		<Fragment>
			<div className={styles.filter}>
				<div className={styles.item}>
					<span className={styles.item_label}>{t('filters.orderComp.period')}</span>

					<div className={styles.item_line}>
						<DatePicker
							key="uniqstart"
							value={isChangeBegin ? dayjs(fields.DateBegin, 'DD.MM.YY') : undefined}
							onChange={onBegin}
							className={classNames(styles.item_line__item, {
								[styles.active]: isChangeBegin,
							})}
							placeholder=""
							locale={language === 'ru' ? localeRu : localeEn}
							allowClear={false}
							format={'DD.MM.YY'}
							suffixIcon={<Icon />}
						/>

						<DatePicker
							key="uniqend"
							value={isChangeEnd ? dayjs(fields.DateEnd, 'DD.MM.YY') : undefined}
							onChange={onEnd}
							className={classNames(styles.item_line__item, {
								[styles.active]: isChangeEnd,
							})}
							placeholder=""
							locale={language === 'ru' ? localeRu : localeEn}
							allowClear={false}
							format={'DD.MM.YY'}
							suffixIcon={<Icon />}
						/>
					</div>
				</div>
			</div>

			<Actions
				confirmButton={<Button title={t('actions.use')} onClick={onSet} />}
				clearButton={<Button title={t('actions.clean')} onClick={onClear} />}
			/>
		</Fragment>
	);
};
