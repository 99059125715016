import React, { useEffect } from 'react';
import styles from './langMenu.module.scss';
import { useLanguage, useLanguageSwitcher } from 'services/contexts/languageContext';
import { ReactComponent as CheckSVG } from 'assets/images/header/lang_check.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchGetUnreadSidebarNotifications } from 'services/store/notifications';

export const LangMenu = () => {
	const { t } = useTranslation();
	const language = useLanguage();
	const dispatch = useDispatch();
	const switchLanguage = useLanguageSwitcher();

	useEffect(() => {
		dispatch(fetchGetUnreadSidebarNotifications(language === 'ru' ? 1 : 2));

		return () => {};
	}, [language]);

	return (
		<div className={styles.lang}>
			<div className={styles.lang_choice}>{t('rightMenu.lang')}</div>

			<div className={classNames(styles.lang_lang, styles.lang_rus)} onClick={switchLanguage}>
				<div className={styles.lang_text}>Русский</div>
				{language === 'ru' && <CheckSVG />}
			</div>

			<div className={classNames(styles.lang_lang, styles.lang_eng)} onClick={switchLanguage}>
				<div className={styles.lang_text}>English</div>
				{language === 'en' && <CheckSVG />}
			</div>
		</div>
	);
};
