import React, { Fragment } from 'react';
import styles from './orderDetail.module.scss';
import { IStoreState, TCurrentOrder } from 'services/store/interfaces';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components/Common/Loading/Loader';
import { CardOrderMobile } from '../../CardOrderMobile';
import { ReactComponent as Close } from 'assets/images/actions/multi_close.svg';
import { Loading } from 'components/Common/Loading';
import { removeOrder } from 'services/store/orders';
import { useTranslation } from 'react-i18next';

interface IOrderDetail {
	type: 'multi' | 'divide';
}
interface ILoaderCArdProps {
	isLeft?: boolean;
}

const PROPERTIES = [
	{ id: 'OrderBuyGroupId', value: 'export.OrderBuyGroupId' },
	{ id: 'Datex', value: 'export.Datex' },
	{ id: 'ShipmentDateLast', value: 'export.ShipmentDate' },
	{ id: 'Checkx', value: 'export.Checkx' },
	{ id: 'WarehouseId', value: 'export.WarehouseId' },
	{ id: 'OrderSum', value: 'export.OrderSumm' },
];
const LoaderCard = ({ isLeft }: ILoaderCArdProps) => {
	return (
		<div className={styles.order}>
			<ul className={styles.properties_list}>
				{PROPERTIES.map(({ id }) => (
					<li key={id} className={classNames(styles.properties_item, { [styles.isLeft]: isLeft })}>
						<Loader />
					</li>
				))}
			</ul>
		</div>
	);
};
export const OrderDetail = ({ type }: IOrderDetail) => {
	const { t } = useTranslation();
	const activeOrders = useSelector((state: IStoreState) => state.orders.activeOrders);
	const isLoadingMulti = useSelector((state: IStoreState) => state.multiOrders.isLoading);
	const isLoadingDivide = useSelector((state: IStoreState) => state.divideOrders.isLoading);

	const multiOrders = useSelector((state: IStoreState) => state.multiOrders.ordersInfo);
	const divideOrders = useSelector((state: IStoreState) => state.divideOrders.ordersInfo);

	const isDivide = type === 'divide';
	const dispatch = useDispatch();

	const orders = {
		multi: multiOrders,
		divide: divideOrders,
	};

	const isLoading = {
		multi: isLoadingMulti,
		divide: isLoadingDivide,
	};

	return (
		<Fragment>
			<div className={styles.desktop}>
				<div className={styles.ordersInfo}>
					<div className={styles.properties}>
						<ul className={styles.properties_list}>
							{PROPERTIES.map(({ id, value }) => (
								<li key={id} className={styles.properties_item}>
									{t(value)}
								</li>
							))}
						</ul>
					</div>

					<div className={styles.orders}>
						{isLoading[type]
							? activeOrders.map((order) => <LoaderCard key={order} isLeft={isDivide} />)
							: orders[type].map((order) => {
									return (
										<div key={order.OrderBuyGroupId} className={styles.order}>
											<ul className={styles.properties_list}>
												{PROPERTIES.map(({ id }) => {
													const isOrderId = id === 'OrderBuyGroupId';

													return (
														<li
															key={id}
															className={classNames(styles.properties_item, {
																[styles.order_id]: isOrderId,
															})}
														>
															{order[id as keyof TCurrentOrder]}
															<span
																onClick={() => dispatch(removeOrder({ id: order[id as keyof TCurrentOrder] }))}
																className={styles.order_close}
															>
																{!isDivide && isOrderId && <Close />}
															</span>
														</li>
													);
												})}
											</ul>
										</div>
									);
							  })}
					</div>
				</div>
			</div>

			<div className={styles.mobile}>
				{isLoading[type] ? (
					<Loading />
				) : (
					orders[type].map((order) => {
						return <CardOrderMobile type={type} key={order.OrderBuyGroupId} order={order} properties={PROPERTIES} />;
					})
				)}
			</div>
		</Fragment>
	);
};
