import React, { FC, Fragment, useState, useEffect } from 'react';
import styles from '../AllGoods/allGoods.module.scss';
import { IStoreState } from 'services/store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { clearReportsPlanning, defaultListReportsPlanning } from 'services/store/filter';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import localeEn from 'antd/es/date-picker/locale/en_US';
import dayjs from 'dayjs';
import { Actions } from 'components/Filter/Actions';
import { Button } from 'components/Filter/Actions/Button';
import classNames from 'classnames';
import { ReactComponent as Icon } from 'assets/images/filter/calendar.svg';
import { getDateFilter } from 'services/utils/js/getFilterDate';
import { TFilterOrdersProps } from '../types';
import type { RangePickerProps } from 'antd/es/date-picker';
import { InputSelect, TSelectField } from 'components/Common/Input/InputSelect';
import { useTranslation } from 'react-i18next';
import { useLanguage } from 'services/contexts/languageContext';
dayjs.extend(customParseFormat);

export const Planning: FC<TFilterOrdersProps> = ({ onChange = () => {}, onSet = () => {} }) => {
	const language = useLanguage();
	const { t } = useTranslation();

	const { goodsInFilter, dateInFilterPlanning, loading } = useSelector((state: IStoreState) => state.reports);
	const { listReportsPlanning, isCleanReportsPlanning } = useSelector((state: IStoreState) => state.filter);

	const [fields, setFilters] = useState(
		isCleanReportsPlanning ? { ...defaultListReportsPlanning } : { ...listReportsPlanning }
	);

	const [endDate, setEndDate] = useState(dateInFilterPlanning);

	const [isChangeBegin, setClearBegin] = useState(false);
	const [isChangeEnd, setClearEnd] = useState(false);

	const dispatch = useDispatch();

	const onBegin = (value: unknown, dateString: string) => {
		setFilters({ ...fields, DateBegin: dateString });
		setClearBegin(true);
	};

	const onEnd = (value: unknown, dateString: string) => {
		const isEmptyBegin = fields.DateBegin === '';
		const isEmptyEnd = dateString === '';
		const DateEnd = isEmptyBegin ? '' : isEmptyEnd ? dayjs().toISOString().slice(0, 10) : dateString;

		setFilters({ ...fields, DateEnd });
		setClearEnd(true);
	};

	const disabledBeginDate: RangePickerProps['disabledDate'] = (current) => {
		return current.isBefore(dayjs(getDateFilter(dateInFilterPlanning)));
	};

	const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
		return current.isBefore(dayjs(getDateFilter(endDate)));
	};

	const onClear = () => {
		setFilters({ ...defaultListReportsPlanning });
		setClearBegin(false);
		setClearEnd(false);

		dispatch(clearReportsPlanning());
	};

	const onGoods = (values: TSelectField[]) => setFilters({ ...fields, GoodsId: values });

	useEffect(() => {
		setEndDate(dateInFilterPlanning);
	}, [dateInFilterPlanning]);

	useEffect(() => {
		if (!fields.DateEnd || !fields.DateBegin) return;

		setFilters({
			...fields,
			DateEnd: dayjs(getDateFilter(fields.DateBegin)) > dayjs(getDateFilter(fields.DateEnd)) ? '' : fields.DateEnd,
		});
	}, [fields.DateBegin]);

	useEffect(() => {
		if (!fields.DateBegin || !fields.DateEnd) return;

		setFilters({ ...fields, DateBegin: fields.DateBegin, DateEnd: fields.DateEnd });
	}, [fields.DateBegin, fields.DateEnd]);

	useEffect(() => {
		let state = { ...fields, GoodsId: fields.GoodsId.map(({ id }) => id) };

		if (fields.DateBegin) {
			const DateBegin = getDateFilter(fields.DateBegin);
			state = { ...state, DateBegin };
		}

		if (fields.DateEnd) {
			const DateEnd = getDateFilter(fields.DateEnd);
			state = { ...state, DateEnd };
		}

		onChange({ ...state });
	}, [JSON.stringify(fields)]);

	useEffect(() => {
		if (!isCleanReportsPlanning) return;

		onClear();
	}, [isCleanReportsPlanning]);

	useEffect(() => {
		if (!fields.DateBegin) return;

		setEndDate(fields.DateBegin);
		setClearEnd(false);
		setFilters({ ...fields, DateEnd: undefined });
	}, [fields.DateBegin]);

	return (
		<Fragment>
			<div className={styles.filter}>
				<div className={styles.item}>
					<span className={styles.item_label}>{t('filters.orderComp.period')}</span>

					<div className={styles.item_line}>
						<DatePicker
							key="uniqstart"
							value={isChangeBegin ? dayjs(fields.DateBegin, 'DD.MM.YY') : undefined}
							onChange={onBegin}
							className={classNames(styles.item_line__item, {
								[styles.active]: isChangeBegin,
							})}
							placeholder=""
							locale={language === 'ru' ? localeRu : localeEn}
							allowClear={false}
							format={'DD.MM.YY'}
							suffixIcon={<Icon />}
							showToday={false}
							disabledDate={disabledBeginDate}
						/>

						<DatePicker
							key="uniqend"
							value={isChangeEnd ? dayjs(fields.DateEnd, 'DD.MM.YY') : undefined}
							onChange={onEnd}
							className={classNames(styles.item_line__item, {
								[styles.active]: isChangeEnd,
							})}
							placeholder=""
							locale={language === 'ru' ? localeRu : localeEn}
							allowClear={false}
							format={'DD.MM.YY'}
							suffixIcon={<Icon />}
							showToday={false}
							disabledDate={disabledEndDate}
						/>
					</div>

					<InputSelect
						title={''}
						initial={{ id: '0', text: t('filters.orderComp.productsSelect') }}
						options={goodsInFilter}
						onChange={onGoods}
						values={fields.GoodsId}
						withSearch
					/>
				</div>
			</div>

			<Actions
				confirmButton={<Button title={t('actions.use')} onClick={loading ? () => {} : onSet} />}
				clearButton={<Button title={t('actions.clean')} onClick={onClear} />}
				disabled={loading}
			/>
		</Fragment>
	);
};
