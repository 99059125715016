import React, { FC } from 'react';
import styles from './Button.module.scss';
import { ReactComponent as ArrowSVG } from 'assets/images/actions/arrow_down.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface IButtonMenu {
	onClick?: () => void;
	isOpen?: boolean;
}
export const Button: FC<IButtonMenu> = ({ onClick, isOpen }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.button} onClick={onClick}>
			<div
				className={classNames(styles.button_text, {
					[styles.button_text__opened]: isOpen,
				})}
			>
				{t('pages.reports')}
			</div>

			<div
				className={classNames(styles.button_arrow, {
					[styles.button_arrow__isClose]: isOpen,
				})}
			>
				<ArrowSVG />
			</div>
		</div>
	);
};
