import React, { FC } from 'react';
import styles from '../../Mass/List/list.module.scss';
import { Modal } from '../../Mass/Modal';
import { useTranslation } from 'react-i18next';

interface IAttentionProps {
	title: string;
	description: string;
	onClose: () => void;
	onConfirm: () => void;
	isAttention: boolean;
}
export const AttentionModal: FC<IAttentionProps> = ({ title, description, onClose, onConfirm, isAttention }) => {
	const { t } = useTranslation();

	return isAttention ? (
		<Modal title={title} description={description} onClose={onClose}>
			<div className={styles.close} onClick={onClose}>
				{t('filters.orderComp.close')}
			</div>

			<div className={styles.confirm} onClick={onConfirm}>
				{t('actions.yes')}
			</div>
		</Modal>
	) : null;
};
