import styles from './tutorial.module.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as LightSVG } from 'assets/images/sidebar/light.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Tutorial = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<div className={styles.tutorial}>
				<Link to={`/PDFUser.pdf?=${new Date().getTime()}`} target="_blank">
					<div className={styles.tutorial__top}>
						<div>{t('rightMenu.howUse')}</div>
						<LightSVG />
					</div>

					<div className={styles.tutorial__bottom}>{t('rightMenu.pdf')}</div>
				</Link>
			</div>
		</div>
	);
};
