import React, { FC, useState, useEffect } from 'react';
import styles from './month.module.scss';
import { DAYS } from '../../DatePanel/Month/data';
import { Week } from './Week';
import { weeksWithDown } from 'services/utils/helpers/dateDownTimes';
import { TDownTimesDayData, TMonth } from 'services/store/interfaces/downTimes';
import { useTranslation } from 'react-i18next';

type TCalendarProps = {
	year: number;
	month: TMonth;
	data: TDownTimesDayData[];
};
export const MonthCalendar: FC<TCalendarProps> = ({ year, month, data }) => {
	const { t } = useTranslation();
	const [isVisible, setIsVisible] = useState(false);
	const [weeks, setWeeks] = useState(weeksWithDown(year, month.id, data));

	useEffect(() => {
		setTimeout(() => setIsVisible(true), 100);
	}, []);

	useEffect(() => {
		setWeeks(weeksWithDown(year, month.id, data));
	}, [JSON.stringify(data), month.id]);

	return (
		<div className={styles.month} style={isVisible ? { opacity: 1 } : { opacity: 0 }}>
			<div className={styles.month_week}>
				{DAYS.map((day) => (
					<div className={styles.month_day} key={day}>
						{t(day)}
					</div>
				))}
			</div>

			{weeks.map(({ days, downs }, inx) => (
				<Week key={inx} days={days} downs={downs} order={inx + 1} />
			))}
		</div>
	);
};
