import {
	TDownTimesDataCount,
	TDownTimesDayData,
	TDownTimesWeeksGroup,
	TMonth,
	TNumDay,
	TNumRequired,
} from 'services/store/interfaces/downTimes';
import { MONTHS } from 'components/Content/DownTimes/DatePanel/Month/data';
import { useTranslation } from 'react-i18next';

export const getDaysInMonth = (y: number, m: number) => {
	return 33 - new Date(y, m, 33).getDate();
};

export const getWeekDays = (y: number, m: number) => {
	const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
	let nums: TNumDay[] = [];

	const count = getDaysInMonth(y, m);

	for (let i = 1; i < count + 1; i++) {
		const date = new Date(y, m, i);
		const day = { day: days[date.getDay()], num: i, year: y, month: m };

		nums = [...nums, day];
	}

	return nums;
};

export const getPositionVS = (nums: (TNumRequired | TNumDay)[]) => {
	const res = nums.map((el, inx) => {
		if (el.day === 'Вс') return inx + 1;
	});

	return res.filter((item) => item !== undefined);
};

export const getLastDaysInMonth = (year: number, month: number, count: number) => {
	const days = getWeekDays(year, month);

	return days.slice(days.length - count);
};

export const getFirstDaysInMonth = (year: number, month: number, count: number) => {
	const days = getWeekDays(year, month);

	return days.slice(0, count);
};

export const getWeeksInMonth = (year: number, month: number, data: TDownTimesDayData[]) => {
	const nums = getWeekDays(year, month);

	const fullDays = nums.map((day) => {
		const groupForDay = data.filter(
			({ Datex, Monthx }) => Number(Datex.split('-')[2]) === day.num && Monthx === day.month
		);
		const isEmpty = groupForDay.length === 0;

		return isEmpty ? day : { ...day, ...groupForDay[0], Info: groupForDay };
	});

	const positions = getPositionVS(fullDays);

	const firstCount = 7 - fullDays.slice(0, positions[0]).length;
	const lastCount = 7 - fullDays.slice(positions[3]).length;

	const first =
		fullDays.slice(0, positions[0]).length < 7
			? [...getLastDaysInMonth(year, month - 1, firstCount), ...fullDays.slice(0, positions[0])]
			: fullDays.slice(0, positions[0]);
	const second = fullDays.slice(positions[0], positions[1]);
	const third = fullDays.slice(positions[1], positions[2]);
	const forth = fullDays.slice(positions[2], positions[3]);
	const fifth =
		fullDays.slice(positions[3]).length < 7
			? [...fullDays.slice(positions[3]), ...getFirstDaysInMonth(year, month + 1, lastCount)]
			: fullDays.slice(positions[3]);

	return [first, second, third, forth, fifth];
};

export const getRowsYear = (months: TMonth[] = MONTHS, size = 4) => {
	const subMonths = [];

	for (let i = 0; i < Math.ceil(months.length / size); i++) {
		subMonths[i] = months.slice(i * size, i * size + size);
	}

	return subMonths;
};

export const getGroupDaysByDayId = (data: TDownTimesDayData[]) => {
	const DayIds = Array.from(new Set(data.map(({ DayId }) => DayId)));

	const groups = DayIds.map((DayId) => {
		const Downs = data.filter((item) => item.DayId === DayId);
		const Info = Downs.map(({ DateStart, DateEnd, Reason, Descript }) => ({ DateStart, DateEnd, Reason, Descript }))[0];
		const Days = Downs.map(({ Datex }) => Datex).map((value) => {
			return Number(value.split('-')[2]);
		});

		return { DayId, ...Info, Days };
	});

	return groups;
};

export const monthByWeeks = (year: number, month: number, data: TDownTimesDayData[]) => {
	const nums = getWeekDays(year, month);

	const fullDays = nums.map((day) => {
		const groupForDay = data.filter(
			({ Datex, Monthx }) => Number(Datex.split('-')[2]) === day.num && Monthx === day.month
		);
		const isEmpty = groupForDay.length === 0;

		return isEmpty ? day : { ...day, ...groupForDay[0], Info: groupForDay };
	});

	const positions = getPositionVS(fullDays);

	const first = fullDays.slice(0, positions[0]);
	const second = fullDays.slice(positions[0], positions[1]);
	const third = fullDays.slice(positions[1], positions[2]);
	const forth = fullDays.slice(positions[2], positions[3]);
	const fifth = fullDays.slice(positions[3]);

	return [first, second, third, forth, fifth];
};

const getCountNumsInSome = (arr1: number[], arr2: number[]) => {
	const result = [];

	for (let i = 0; i < arr1.length; i += 1) {
		if (arr2.includes(arr1[i])) result.push(arr1[i]);
	}

	return result.length;
};

const getDowns = (downs: TDownTimesDataCount[]) => {
	const downsNew: TDownTimesDataCount[] = [];

	for (let i = 0; i < downs.length; i++) {
		downsNew[i] = {
			...downs[i],
			prevIndex:
				i === 0
					? 0
					: downs[i].prevCount === 0 && downs[i].lastCommon === 0
					? 0
					: downs[i].prevCount === 0 && downs[i].lastCommon !== 0
					? downsNew[i - 1].prevIndex
					: downsNew[i - 1].prevIndex + 1 > 2
					? 0
					: downsNew[i - 1].prevIndex + 1,
		};
	}

	return downsNew;
};

export const weeksWithDown = (year: number, month: number, data: TDownTimesDayData[]): TDownTimesWeeksGroup[] => {
	const weeks = monthByWeeks(year, month, data);
	const groups = getGroupDaysByDayId(data);

	const res = weeks.map((week) => {
		const days = week.map(({ num }) => num);

		const weekGroups = Array.from(
			new Set(
				days
					.map((day) => {
						return groups.filter(({ Days }) => Days.includes(day));
					})
					.flat()
			)
		).sort((a, b) => {
			const lastStart = Number(b.DateStart.split('-')[2]);
			const firstStart = Number(a.DateStart.split('-')[2]);

			return firstStart - lastStart;
		});

		const downs: TDownTimesDataCount[] = weekGroups.map((group, i) => {
			const slices = weekGroups.slice(0, i);
			const futureSlices = weekGroups.slice(i + 1);

			const lastCommon = slices.filter(({ Days }) => getCountNumsInSome(group.Days, Days)).length;
			const futureCount = futureSlices.filter(({ Days }) => getCountNumsInSome(group.Days, Days)).length;
			const prevCount = i > 0 ? getCountNumsInSome(group.Days, weekGroups[i - 1].Days) : 0;
			const isOne = futureCount === 0 && lastCommon === 0 && group.Days.length === 1;

			return {
				...group,
				lastCommon,
				isOne,
				currCount: getCountNumsInSome(group.Days, days),
				prevCount,
				prevDays: i > 0 ? weekGroups[i - 1].Days : [],
				prevIndex: 0,
			};
		});

		return { days: week, downs: getDowns(downs) };
	});

	return res;
};

export const getDate = (Datex: string, isYear = false) => {
	const { t } = useTranslation();
	const [year, month, day] = Datex.split('-');
	const months: Record<number, string> = {
		1: 'period.september',
		2: 'period.february',
		3: 'period.march',
		4: 'period.april',
		5: 'period.may',
		6: 'period.june',
		7: 'period.july',
		8: 'period.august',
		9: 'period.september',
		10: 'period.october',
		11: 'period.november',
		12: 'period.december',
	};
	const dd = day.includes('0') && !['10', '20', '30'].includes(day) ? day.slice(1) : day;
	const mm = months[Number(month.includes('0') && !month.includes('10') ? month.slice(1) : month)];

	return `${dd} ${t(mm)} ${isYear ? year : ''}`;
};

export const getIsFutureDown = (dateEnd: string | undefined) => {
	if (!dateEnd) return;

	const dateDay = new Date(dateEnd).toDateString();
	const currentDate = Date.parse(new Date().toDateString());
	const days = (currentDate - Date.parse(dateDay)) / 86400000;
	return Math.round(days) < 0;
};
