import React, { Fragment, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { preventDefault } from 'services/utils/react/preventDefault';
import { useTranslation } from 'react-i18next';

interface IProps {
	text?: string;
	children?: React.ReactNode;
	fixed?: boolean;
	visible?: boolean;
}

export const Footer = ({ children, text = 'operations.withOrders', fixed, visible }: IProps) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);

	const breakpoint = useBreakpoint();
	const isMobile = breakpoint.includes('xs');

	const hidden = () => setIsOpen(false);
	const show = () => setIsOpen(true);

	return (
		<div
			className={classNames(styles.footer, {
				[styles.footer_fixed]: fixed,
				[styles.footer_visible]: visible,
			})}
		>
			<div className={styles.footer_copyright}>
				{t(text)}
				{isMobile && (
					<Fragment>
						{isOpen && (
							<a className={styles.footer_action} onClick={preventDefault(hidden)}>
								{t('actions.hide')}
							</a>
						)}
						{!isOpen && (
							<a className={styles.footer_action} onClick={preventDefault(show)}>
								{t('actions.show')}
							</a>
						)}
					</Fragment>
				)}
			</div>

			{isOpen && isMobile && <div className={styles.footer_actions}>{children}</div>}
			{!isMobile && <div className={styles.footer_actions}>{children}</div>}
		</div>
	);
};
