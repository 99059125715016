export const REPORTS = [
	{ id: 'allGoods', text: 'reports.allGoods', link: '/reports/allGoods' },
	{ id: 'quality', text: 'reports.quality', link: '/reports/quality' },
	{ id: 'current', text: 'reports.current', link: '/reports/current' },
	{ id: 'planning', text: 'reports.planning', link: '/reports/planning' },
];

export const Titles = {
	allGoods: 'reports.allGoods',
	quality: 'reports.quality',
	current: 'reports.current',
	planning: 'reports.planning',
};
