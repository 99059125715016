import React, { Fragment } from 'react';
import { DropButton } from 'components/Common/DropButton';
import { ReactComponent as BellSVG } from 'assets/images/events/bell.svg';
import { Clock } from 'assets/images/downTimes/Clock';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import styles from './eventsActions.module.scss';
import classNames from 'classnames';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { setType } from 'services/store/events';
import { setFiltersEvents, setFiltersMessages } from 'services/store/filter';
import { CalendarSVG } from 'assets/images/events';
import { Desktop } from 'components/HOCS/Desktop';
import { useTranslation } from 'react-i18next';

export const EventsActions = () => {
	const { t } = useTranslation();
	const countEvents = useSelector((state: IStoreState) => state.events.count);
	const countPageNotifications = useSelector((state: IStoreState) => state.notifications.unreadCount);
	const filterEvents = useSelector((state: IStoreState) => state.filter.listEvents);
	const mode = useSelector((state: IStoreState) => state.events.type);

	const isShowEvents = mode === 'events';
	const isShowNotifications = mode === 'notifications';
	const isShowDownTimes = mode === 'downTimes';

	const dispatch = useDispatch();
	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');

	const countEventsText = isMobile ? '' : `(${countEvents})`;
	const countNotificationsText = isMobile ? '' : `(${countPageNotifications})`;

	return (
		<Fragment>
			<DropButton
				text={`${t('events.calendar')} ${countEventsText}`}
				icon={<CalendarSVG color={isShowEvents ? '#fff' : '#606060'} />}
				onClick={() => {
					dispatch(setFiltersEvents({ filters: filterEvents }));
					dispatch(setType('events'));
				}}
				mix={classNames(styles.event, {
					[styles.active]: isShowEvents,
				})}
			/>

			<DropButton
				text={`${t('events.notifications')} ${countNotificationsText}`}
				icon={<BellSVG />}
				onClick={() => {
					dispatch(setType('notifications'));
					dispatch(setFiltersMessages({ filters: filterEvents }));
				}}
				mix={classNames(styles.event, {
					[styles.active]: isShowNotifications,
				})}
			/>

			<Desktop>
				<DropButton
					text={t('events.downtimes')}
					icon={<Clock active={isShowDownTimes} />}
					onClick={() => {
						dispatch(setType('downTimes'));
						// dispatch(setFiltersMessages({ filters: filterEvents }));
					}}
					mix={classNames(styles.event, {
						[styles.active]: isShowDownTimes,
					})}
				/>
			</Desktop>
		</Fragment>
	);
};
