import React, { memo } from 'react';
import styles from './groupTitle.module.scss';
import { ReactComponent as HelpSVG } from 'assets/images/grouping/help.svg';
import { useTranslation } from 'react-i18next';

export const GroupTitle = memo(() => {
	const { t } = useTranslation();

	return (
		<div className={styles.box__left}>
			<HelpSVG />

			<div className={styles.box__left_text}>{t('filters.grouping')}</div>
		</div>
	);
});
