import React, { FC, useContext, useState, useCallback, useEffect } from 'react';
import i18next from 'i18next';

const LanguageContext = React.createContext(localStorage.lg !== 'undefined' ? localStorage.lg : 'ru');
const LanguageSwitcherContext = React.createContext(() => {});

export const useLanguage = () => {
	return useContext(LanguageContext);
};

export const useLanguageSwitcher = () => {
	return useContext(LanguageSwitcherContext);
};

export const LanguageProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	const [language, setLanguage] = useState<string>(localStorage.lg != 'undefined' ? localStorage.lg : 'ru');

	useEffect(() => {
		localStorage.setItem('lg', language ? language : 'ru');
		setLanguage(language ? language : 'ru');
	}, [language]);

	useEffect(() => {
		i18next.changeLanguage(language).then((r) => r);
	}, [language]);

	const switchLanguage = useCallback(() => {
		setLanguage((prevLang) => (prevLang === 'ru' ? 'en' : 'ru'));
	}, []);

	return (
		<LanguageSwitcherContext.Provider value={switchLanguage}>
			<LanguageContext.Provider value={language}>{children}</LanguageContext.Provider>
		</LanguageSwitcherContext.Provider>
	);
};
