import React from 'react';
import styles from './linksAuth.module.scss';
import { LINKS } from '../links';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AuthLinks = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.links}>
			<div className={styles.links_group}>
				{LINKS.map(({ id, text, link }) => {
					const isExternal = link.includes('http');

					return (
						<div key={id} className={styles.links_item}>
							{isExternal ? (
								<a target="_blank" href={link} rel="noreferrer">
									{t(text)}
								</a>
							) : (
								<Link to={link}>{t(text)}</Link>
							)}
						</div>
					);
				})}
			</div>

			<div className={styles.links_copyright}>
				© {new Date().getFullYear()} {t('rightMenu.ooo')}
			</div>
		</div>
	);
};
