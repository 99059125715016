import React from 'react';
import { Logo } from 'components/Logo';
import { Icons } from '../HeaderWithAuth/Icons';
import styles from './index.module.scss';

const HeaderWithoutAuth = () => {
	return (
		<div className={styles.HeaderWithoutAuth}>
			<Logo />
			<Icons />
		</div>
	);
};

export default HeaderWithoutAuth;
