import React, { FC } from 'react';
import { TPropItemList } from '../index';
import styles from './dragzone.module.scss';
import { GroupTitle } from 'components/Grouping/GroupTitle';
import { PropsDesc } from 'components/Grouping/PropDesk';
import { PropItem } from 'components/Grouping/PropItem';
import { Reorder, AnimatePresence, motion } from 'framer-motion';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setIsStartGroup } from 'services/store/grouping';
import { useTranslation } from 'react-i18next';

type TDragZoneType = {
	propsResult: TPropItemList[];
	setPropsResult: (values: TPropItemList[]) => void;
	dragged: HTMLElement | null;
};

const variants = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
	},
	exit: {
		opacity: 0,
	},
};

const propsInList = (list: Array<TPropItemList>, propId: string) => {
	const result = list.filter(({ id }) => id === propId);

	return result.length > 0;
};
export const DragZone: FC<TDragZoneType> = ({ propsResult, setPropsResult, dragged }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		if (!dragged || !(e.target as HTMLElement).classList.contains('dropzone')) return;
		const { title, id } = dragged.dataset;

		if (!propsInList(propsResult, id || '')) {
			setPropsResult([...propsResult, { id: id || '', title: title || '' }]);
		}
	};

	const onClose = (value: string) => {
		setPropsResult(propsResult.filter(({ id }) => id !== value));
	};

	return (
		<AnimatePresence>
			<motion.div className={styles.box} {...variants}>
				<GroupTitle />

				<div className={styles.box__right} data-title="right" onDragOver={(e) => e.preventDefault()} onDrop={onDrop}>
					<AnimatePresence>
						<PropsDesc isVisible={propsResult.length === 0}>
							<Reorder.Group
								className={classNames(styles.box__result, 'dropzone')}
								as="div"
								axis="x"
								values={propsResult}
								onReorder={setPropsResult}
							>
								{propsResult.map((prop) => (
									<Reorder.Item
										value={prop}
										key={prop.title}
										whileDrag={{
											scale: 1.15,
											opacity: 0.8,
										}}
										onDrag={() => dispatch(setIsStartGroup(false))}
										onDragEnd={() => dispatch(setIsStartGroup(true))}
										{...variants}
									>
										<PropItem title={t(prop.title)} onClose={() => onClose(prop.id)} />
									</Reorder.Item>
								))}
							</Reorder.Group>
						</PropsDesc>
					</AnimatePresence>
				</div>
			</motion.div>
		</AnimatePresence>
	);
};
