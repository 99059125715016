import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export const Title = () => {
	const { t } = useTranslation();

	return (
		<Helmet>
			<title>{t('title')}</title>
		</Helmet>
	);
};
