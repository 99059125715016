import React from 'react';
import styles from './order.module.scss';
import { ReactComponent as Close } from 'assets/images/actions/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderBuy, fetchOrderBuyGroup, removeCurrentGoods, removeOrder, setCurrent } from 'services/store/orders';
import classNames from 'classnames';
import { IStoreState } from 'services/store/interfaces';
import { setFilterOrderGoods, setFiltersOrders } from 'services/store/filter';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { motion } from 'framer-motion';
import { useLanguage } from 'services/contexts/languageContext';

interface IOrderProps {
	id: string;
	onClose?: (id: string) => void;
}

export const Order = ({ id }: IOrderProps) => {
	const language = useLanguage();

	const dispatcher = useDispatch();
	const history = useHistory();

	const current = useSelector((state: IStoreState) => state.orders.current);
	const filters = useSelector((state: IStoreState) => state.filter.listOrders);

	const onClose = (id: string) => {
		dispatcher(removeOrder({ id }));

		if (current === id) {
			dispatcher(setFiltersOrders({ filters: { PageNumber: 1, Limit: 30 } }));
			dispatcher(setCurrent({ id: '0' }));
			dispatcher(fetchOrderBuyGroup(filters));

			history.push('/orders');
		}
	};

	const onClick = () => {
		dispatcher(setFilterOrderGoods());
		dispatcher(setCurrent({ id }));
		dispatcher(removeCurrentGoods());
		dispatcher(fetchOrderBuy({ OrderBuyGroupId: [id], Language: language === 'ru' ? 1 : 2 }));
	};

	return (
		<motion.div
			className={classNames(styles.order, {
				[styles.order__active]: current === id,
			})}
			initial={{ opacity: 0.5, x: 20 }}
			animate={{ opacity: 1, x: 0 }}
			exit={{ opacity: 0, x: 20 }}
			transition={{ duration: 0.5 }}
		>
			<span className={styles.order__number} onClick={onClick}>
				<Link to={`/orders/${id}`}>№{id}</Link>
			</span>

			<span className={styles.order__close} onClick={() => onClose(id)}>
				<Close />
			</span>
		</motion.div>
	);
};
