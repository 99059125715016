import React, { FC, useState, useEffect, useRef } from 'react';
import { Portal } from 'components/Modals';
import styles from './modal.module.scss';
import { ReactComponent as CloseSVG } from 'assets/images/downTimes/close.svg';
import classNames from 'classnames';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import localeEn from 'antd/es/date-picker/locale/en_US';
import { InputText } from 'components/Common/Input/InputText';
import { ReactComponent as Icon } from 'assets/images/filter/calendar.svg';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Textarea } from 'components/Common/Input/Textarea';
import { getDateFilter, getDateMountedFilter } from 'services/utils/js/getFilterDate';
import { fetchUpdateDowntimesWithUpdate, setMessage } from 'services/store/downTimes';
import { useDispatch, useSelector } from 'react-redux';
import { TUpdateDownTimesPayload } from 'services/store/interfaces/downTimes';
import { IStoreState } from 'services/store/interfaces';
import type { RangePickerProps } from 'antd/es/date-picker';
import 'dayjs/locale/ru';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useLanguage } from 'services/contexts/languageContext';
import { useTranslation } from 'react-i18next';

dayjs.extend(customParseFormat);

type TModalProps = {
	onClose: () => void;
	Monthx?: number;
	Datex?: string | number;
	DayId?: string;
	DateStart?: string;
	DateEnd?: string;
	Reason?: string;
	Descript?: string;
	ActionType: '1' | '2';
};

const disabledCreateDate: RangePickerProps['disabledDate'] = (current) => {
	const date = new Date();
	const point = date.setDate(date.getDate() + 14);

	return current && current.isBefore(point);
};

export const ModalDownTimes: FC<TModalProps> = ({ onClose, ActionType, ...props }) => {
	const { t } = useTranslation();
	const language = useLanguage();
	const { year, month, message, loading, mode } = useSelector((state: IStoreState) => state.downTimes);
	const [state, setState] = useState({
		...props,
		DateBegin: props.DateStart
			? getDateMountedFilter(props.DateStart)
			: dayjs(new Date().setDate(new Date().getDate() + 15)).format('DD.MM.YY'),
		DateEnd: props.DateEnd ? getDateMountedFilter(props.DateEnd) : '',
		Description: props.Descript || '',
		Reason: props.Reason || '',
		ActionType,
	});
	const [errors, setErrors] = useState({ Reason: '', Date: '' });
	const dispatch = useDispatch();
	const ref = useRef<HTMLDivElement>(null);

	const onSetBegin = (value: unknown, dateString: string) => {
		dispatch(setMessage([]));
		setState({ ...state, DateBegin: dateString });
	};

	const onSetEnd = (value: unknown, dateString: string) => {
		const isEmptyBegin = state.DateBegin === '';
		const isEmptyEnd = dateString === '';
		const DateEnd = isEmptyBegin ? '' : isEmptyEnd ? dayjs().toISOString().slice(0, 10) : dateString;
		dispatch(setMessage([]));
		setState({ ...state, DateEnd });
	};

	const onCreate = () => {
		setErrors({ Reason: '', Date: '' });
		const { Reason, DateBegin, DateEnd } = state;

		const isReasonError =
			Reason.trim().length === 0
				? t('events.errReason')
				: Reason.trim().length < 5
				? t('events.5points')
				: Reason.trim().length > 50
				? t('events.50points')
				: '';

		setErrors({
			...errors,
			Reason: isReasonError,
			Date: DateBegin === '' || DateEnd === '' ? t('events.corrTime') : '',
		});

		if (isReasonError.length === 0 && DateBegin !== '' && DateEnd !== '') {
			const data = {
				...state,
				DateBegin: getDateFilter(state.DateBegin),
				DateEnd: getDateFilter(state.DateEnd),
				ActionType,
			};

			dispatch(
				fetchUpdateDowntimesWithUpdate(data as unknown as TUpdateDownTimesPayload, String(year), String(month.id), mode)
			);
		}
	};

	useEffect(() => {
		if (!message[0]) return;

		dispatch(setMessage([]));
	}, [JSON.stringify(state)]);

	useEffect(() => {
		if (message[0] && message[0].type === 'success') {
			setTimeout(
				() => {
					setState(state.ActionType === '1' ? { ...state, Reason: '', Description: '' } : { ...state });
					dispatch(setMessage([]));
				},
				state.ActionType === '1' ? 1500 : 3000
			);
		}
	}, [JSON.stringify(message)]);

	const disabledEditEndDate: RangePickerProps['disabledDate'] = (current) => {
		const date1 = new Date();
		const year = date1.getFullYear();
		const month = date1.getMonth();
		const day = date1.getDate();
		const date = new Date(year, month, day, 0);
		const endPoint = date.setDate(date.getDate() + 14);
		const startPoint = dayjs(props.DateStart);
		const dateStart = dayjs(getDateFilter(state.DateBegin));
		const dateEnd = dayjs(props.DateEnd);

		return dayjs(endPoint) > startPoint || dayjs(endPoint).toString() === startPoint.toString()
			? current.isBefore(dateStart) || current.isAfter(dateEnd)
			: current.isBefore(dateStart);
	};

	const disabledEditStartDate: RangePickerProps['disabledDate'] = (current) => {
		const date = new Date();
		const endPoint = date.setDate(date.getDate() + 14);
		const dateStart = dayjs(props.DateStart);
		const dateEnd = dayjs(props.DateEnd);

		return dateStart < dayjs()
			? current.isBefore(dayjs()) || current.isAfter(dayjs())
			: dayjs(endPoint) > dateStart
			? current.isBefore(dateStart) || current.isAfter(dateEnd)
			: current.isBefore(endPoint);
	};

	const disabledCreateEndDate: RangePickerProps['disabledDate'] = (current) => {
		const date = new Date();
		const point = date.setDate(date.getDate() + 14);
		const startDate = dayjs(getDateFilter(state.DateBegin));

		return state.DateBegin && startDate > dayjs(point)
			? current && current.isBefore(startDate)
			: current && current.isBefore(point);
	};

	useEffect(() => {
		setState({
			...state,
			DateEnd: dayjs(getDateFilter(state.DateBegin)) > dayjs(getDateFilter(state.DateEnd)) ? '' : state.DateEnd,
		});
	}, [state.DateBegin]);

	return (
		<Portal>
			<div
				ref={ref}
				className={styles.modal}
				onMouseDown={(e) => {
					if (e.target === ref?.current) onClose();
				}}
			>
				<div className={styles.modal_body}>
					<div className={styles.modal_close} onClick={onClose}>
						<CloseSVG />
					</div>

					<div className={styles.modal_title}>
						{t(state.ActionType === '1' ? 'events.addDowntime' : 'events.editDowntime')}
					</div>

					<div className={classNames(styles.field, { [styles.field_error]: errors.Reason !== '' })}>
						<div className={classNames(styles.field_label, styles.field_label_required)}>{t('events.reason')}</div>

						<InputText onChange={(Reason) => setState({ ...state, Reason })} value={state.Reason} />
					</div>

					<div
						className={classNames(styles.error, {
							[styles.error_error]: errors.Reason !== '',
						})}
					>
						{errors.Reason}
					</div>

					<div className={classNames(styles.field, { [styles.field_error]: errors.Date !== '' })}>
						<div className={classNames(styles.field_label, styles.field_label_required)}>
							{t('events.specifyPeriod')}
						</div>

						<div className={styles.field_line}>
							<DatePicker
								key="uniqstart"
								value={state.DateBegin !== '' ? dayjs(state.DateBegin, 'DD.MM.YY') : undefined}
								onChange={onSetBegin}
								className={classNames(styles.field_line__item, {
									[styles.active]: state.DateBegin !== '',
								})}
								disabledDate={state.ActionType === '1' ? disabledCreateDate : disabledEditStartDate}
								placeholder=""
								locale={language === 'ru' ? localeRu : localeEn}
								allowClear={false}
								format={'DD.MM.YY'}
								suffixIcon={<Icon />}
								defaultValue={
									state.DateBegin === ''
										? dayjs(new Date().setDate(new Date().getDate() + 15))
										: dayjs(state.DateBegin, 'DD.MM.YY')
								}
								showToday={false}
							/>

							<DatePicker
								key="uniqend"
								value={state.DateEnd !== '' ? dayjs(state.DateEnd, 'DD.MM.YY') : undefined}
								onChange={onSetEnd}
								className={classNames(styles.field_line__item, {
									[styles.active]: state.DateEnd !== '',
								})}
								disabledDate={state.ActionType === '1' ? disabledCreateEndDate : disabledEditEndDate}
								locale={language === 'ru' ? localeRu : localeEn}
								allowClear={false}
								format={'DD.MM.YY'}
								suffixIcon={<Icon />}
								defaultValue={state.DateEnd === '' ? undefined : dayjs(state.DateEnd, 'DD.MM.YY')}
								showToday={false}
								placeholder=""
							/>
						</div>
					</div>

					<div
						className={classNames(styles.error, {
							[styles.error_error]: errors.Date !== '',
						})}
					>
						{errors.Date}
					</div>

					<div className={styles.field}>
						<div className={styles.field_label}>{t('events.additional')}</div>

						<Textarea
							onChange={(Description) => setState({ ...state, Description })}
							value={String(state.Description)}
						/>
					</div>

					<div
						className={classNames(styles.message, {
							[styles.message_hidden]: message.length === 0,
							[styles.message_success]: message.length > 0 && message[0].type === 'success',
							[styles.message_error]: message.length > 0 && message[0].type === 'error',
						})}
					>
						{message[0] && message[0].text}
					</div>

					<div className={styles.modal_actions}>
						<div className={classNames(styles.modal_action, styles.modal_cancel)} onClick={onClose}>
							{t('events.cancel')}
						</div>

						<div
							className={classNames(styles.modal_action, styles.modal_confirm, {
								[styles.modal_disabled]: loading,
							})}
							onClick={loading ? () => {} : onCreate}
						>
							{t('actions.yes')}
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
};
