import React, { memo } from 'react';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import styles from 'components/Content/Events/FilterEvents/filterEvents.module.scss';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import localeEn from 'antd/es/date-picker/locale/en_US';
import { useLanguage } from 'services/contexts/languageContext';
import { Dayjs } from 'dayjs';

interface IDateFieldsProps {
	onBegin: (value: unknown, dateString: string) => void;
	onEnd: (value: unknown, dateString: string) => void;
	begin?: null | Dayjs;
	end?: null | Dayjs;
}
export const DateFields = memo(({ onBegin, onEnd, begin, end }: IDateFieldsProps) => {
	const language = useLanguage();

	return (
		<div className={classNames(styles.item_line)}>
			<DatePicker
				onChange={onBegin}
				value={begin}
				className={styles.item_line__item}
				placeholder=""
				locale={language === 'ru' ? localeRu : localeEn}
				allowClear={false}
				showToday={false}
			/>

			<DatePicker
				onChange={onEnd}
				value={end}
				className={styles.item_line__item}
				placeholder=""
				locale={language === 'ru' ? localeRu : localeEn}
				allowClear={false}
				showToday={false}
			/>
		</div>
	);
});
