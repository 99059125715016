import React from 'react';
import styles from '../UserMenu/userMenu.module.scss';
import { useTranslation } from 'react-i18next';

export const HelpMenu = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.userMenu}>
			<div className={styles.header}>
				<div className={styles.title}>{t('rightMenu.interest')}...</div>
			</div>
		</div>
	);
};
