import React from 'react';
import styles from './detailOrder.module.scss';
import { Property } from './Property';
import { TCurrentOrder } from 'services/store/interfaces';
import { is10Correct, isCorrected, isUpdate } from 'services/utils/js/getOrderStatus';
import { Status } from 'components/Common/StatusRow';
import { useTranslation } from 'react-i18next';

interface IDetailOrderProps {
	order: TCurrentOrder;
}

export const DetailOrder = ({ order }: IDetailOrderProps) => {
	const { t } = useTranslation();
	const { OrderBuyGroupId, Datex, ShipmentDateLast, WarehouseId, OrderSum, DepartmentAddress } = order;

	const isEdited = isUpdate(order);
	const isCorrect = isCorrected(order) || is10Correct(order);

	return OrderBuyGroupId ? (
		<div className={styles.detail}>
			<Property name={t('export.OrderBuyGroupId')} value={OrderBuyGroupId} />

			<Property
				name={t('export.Datex')}
				id={OrderBuyGroupId}
				value={Datex}
				dateStart={Datex}
				type={isEdited ? 'update' : isCorrect ? 'correct' : 'other'}
				dateEnd={ShipmentDateLast}
			/>

			<Property name={t('export.Checkx')} value={<Status order={order} type="detail" />} />
			<Property name={t('export.WarehouseId')} value={WarehouseId} />

			<Property name={t('export.DepartmentAddress')} value={DepartmentAddress} />
			<Property name={t('export.ShipmentDate')} value={OrderSum ? `${OrderSum} ${t('export.Rub')}.` : ''} />
		</div>
	) : null;
};

export default DetailOrder;
