import React, { useCallback, useMemo } from 'react';
import { MainLayout } from 'layouts/Main';
import { Goods } from 'components/Content/Goods';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TGetContractorGoodsResponse } from 'services/store/interfaces';
import { Footer } from 'components/Footer';
import { ActionButton } from 'components/Common/ActionButton';
import { setClipboard } from 'services/store/goods';
import { clipboardGoods } from 'components/Clipboard/ClipboardGoods';
import { PrintItems } from 'components/Print/PrintItems';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { ClearFilter } from 'components/Common/ActionButton/ClearFilter';
import { exportToCSV } from 'services/utils/js/exportToCSV';
import { ModalW } from 'components/Modals';
import { useTranslation } from 'react-i18next';

export const GoodsPage = () => {
	const { t } = useTranslation();
	const settingsGoods = useSelector((state: IStoreState) => state.setting.listGoods);
	const activeGoods = useSelector((state: IStoreState) => state.goods.activeGoods);
	const clipboard = useSelector((state: IStoreState) => state.goods.clipboard);
	const goods = useSelector((state: IStoreState) => state.goods.goodsList);
	const isLoadingGoods = useSelector((state: IStoreState) => state.goods.isLoading);

	const isFilterGoods = useSelector((state: IStoreState) => state.filter.isShowGoods);
	const isSettingsGoods = useSelector((state: IStoreState) => state.setting.isShowGoods);
	const filteredSettings = settingsGoods.filter((setting) => setting.isShow);

	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');

	const goodsFiltered = activeGoods
		.map((id) => {
			return goods.filter(({ Id }) => Id === id);
		})
		.flat(2);

	const filteredTitleGoods = goodsFiltered.map((good) => {
		const value = good.GoodsName?.replace(/["]/g, '');

		return { ...good, GoodsName: value };
	});

	const resGoods = filteredTitleGoods.map((good) => {
		return filteredSettings.reduce(
			(acc, { id }) => ({ ...acc, [id]: good[id as keyof TGetContractorGoodsResponse] }),
			{}
		);
	});

	const modalIsOpen = useMemo(() => clipboard.text !== '', [clipboard]);
	const dispatch = useDispatch();

	const isFooterMobile = !isFilterGoods && !isSettingsGoods && isMobile;
	const isFooterDesktop = !isMobile && activeGoods.length > 0;

	const onClose = useCallback(() => dispatch(setClipboard({ text: '' })), []);
	const setMes = useCallback(() => dispatch(setClipboard({ text: t('user.copied') })), []);

	return (
		<MainLayout>
			<Goods />

			<Footer text={t('goods.operations')} fixed={isLoadingGoods} visible={isFooterMobile || isFooterDesktop}>
				<ActionButton
					text={t('export.Export')}
					type="export"
					onClick={() => exportToCSV(resGoods, t('goods.export'), [])}
				/>
				<PrintItems headers={filteredSettings} body={resGoods} isMobile={isMobile} />

				<ActionButton
					text={t('events.copy')}
					type="buffer"
					onClick={() => clipboardGoods(settingsGoods, goodsFiltered, setMes)}
				/>

				{isFooterMobile && <ClearFilter type="goods" text={t('goods.clearFilters')} mobile={true} />}
			</Footer>

			<ModalW isOpen={modalIsOpen} message={clipboard.text} onCancel={onClose} />
		</MainLayout>
	);
};
