import React, { useRef, useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { IStoreState } from 'services/store/interfaces';
import { useSelector } from 'react-redux';
import styles from './schedule.module.scss';
import { Week } from './Week';
import { getYAxisValues } from 'services/utils/helpers/getYAxisValues';
import classNames from 'classnames';
import { Tooltip } from 'components/Common/Tooltip';
import { TDemandScheduleItemData } from 'services/store/interfaces/reports';
import { useTranslation } from 'react-i18next';

export const Schedule = () => {
	const { t } = useTranslation();
	const { listSchedulePlanning } = useSelector((state: IStoreState) => state.reports);

	const ref = useRef<HTMLDivElement>(null);
	const schedule = useRef<HTMLDivElement>(null);
	const [height, setHeight] = useState<number>();

	const [x, setX] = useState<number>();
	const [y, setY] = useState<number>();
	const [item, setItem] = useState<TDemandScheduleItemData | null>();
	const [isTooltip, setIsTooltip] = useState(false);

	const axisValues = useMemo(
		() =>
			getYAxisValues(
				0,
				listSchedulePlanning[0].MAX_ORDER_QTY,
				Math.floor(listSchedulePlanning[0].MAX_ORDER_QTY / 100) * 10
			),
		[JSON.stringify(listSchedulePlanning)]
	);

	useEffect(() => {
		if (!ref.current) return;

		setHeight((ref.current.offsetHeight - 56) / axisValues[axisValues.length - 1]);
	}, [ref]);

	return (
		<motion.div
			className={styles.panel}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.5 }}
		>
			{schedule.current && schedule.current.scrollWidth > schedule.current.clientWidth && (
				<div className={styles.panel__scroll} />
			)}

			<div className={classNames(styles.name)} onMouseEnter={() => setIsTooltip(false)}>
				{t('reports.unitsNumber')}
			</div>

			<div className={styles.axisY} ref={ref} onMouseEnter={() => setIsTooltip(false)}>
				{axisValues.map((value, inx) => {
					return (
						<div key={inx} className={styles.axisY_row}>
							<div className={styles.axisY_value}>{value}</div>
							<div className={styles.axisY_line} />
						</div>
					);
				})}
			</div>

			<div
				className={styles.schedule}
				style={{
					height: axisValues.length * 56,
					bottom: schedule.current ? (schedule.current.scrollWidth > schedule.current.clientWidth ? -41 : -35) : -35,
				}}
				onMouseEnter={() => setIsTooltip(false)}
				ref={schedule}
			>
				{listSchedulePlanning.map((props, inxWeek) => {
					return (
						<Week
							key={inxWeek}
							baseHeight={height}
							step={Math.floor(listSchedulePlanning[0].MAX_ORDER_QTY / 100) * 10}
							isBlue={inxWeek % 2 === 1}
							isGreen={inxWeek % 2 !== 1}
							setItem={setItem}
							itemTooltip={item}
							setIsTooltip={setIsTooltip}
							setX={setX}
							setY={setY}
							y={y}
							{...props}
						/>
					);
				})}
			</div>

			{isTooltip && (
				<Tooltip x={x} y={y}>
					{item && (
						<div className={styles.tooltip}>
							<div className={styles.tooltip_code}>SCU {item.ITEM_CODE}</div>
							<div className={styles.tooltip_name}>{item.ITEM_DESCR}</div>
							<div className={styles.tooltip_quantity}>{item.SUM_ORDER_QTY} шт</div>
						</div>
					)}
				</Tooltip>
			)}
		</motion.div>
	);
};
