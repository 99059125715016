import React, { useEffect } from 'react';
import { OrderDetail } from '../OrderDetail/';
import styles from './ordersInfo.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from 'services/store/interfaces';
import { fetchMultiOrderBuy } from 'services/store/multiorders';
import { GoodsDetail } from '../GoodsDetail';
import { Desktop } from 'components/HOCS/Desktop';
import { setCurrent } from 'services/store/orders';
import { useLanguage } from 'services/contexts/languageContext';

export const OrdersInfo = () => {
	const language = useLanguage();
	const activeOrders = useSelector((state: IStoreState) => state.orders.activeOrders);
	const isUpdated = useSelector((state: IStoreState) => state.multiOrders.isUpdated);
	const multiMessage = useSelector((state: IStoreState) => state.multiOrders.message.text);
	const isOneActiveOrder = activeOrders.length === 1;
	const isEmptyMessage = multiMessage === '';
	const dispatch = useDispatch();

	useEffect(() => {
		const setInfo = () =>
			isOneActiveOrder
				? dispatch(setCurrent({ id: activeOrders[0] }))
				: dispatch(
						fetchMultiOrderBuy({ OrderBuyGroupId: activeOrders, Limit: 1000, Language: language === 'ru' ? 1 : 2 })
				  );

		setInfo();

		return () => {};
	}, [activeOrders]);

	useEffect(() => {
		if (isUpdated && isEmptyMessage)
			dispatch(fetchMultiOrderBuy({ OrderBuyGroupId: activeOrders, Limit: 1000, Language: language === 'ru' ? 1 : 2 }));
	}, [isUpdated, isEmptyMessage]);

	return (
		<div className={styles.ordersInfo}>
			<OrderDetail type="multi" />

			<Desktop>
				<GoodsDetail type="multi" />
			</Desktop>
		</div>
	);
};
