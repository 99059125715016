import React from 'react';
import { AuthLayout } from 'layouts/Auth';
import { Form } from './Form';

export const LoginPage = () => {
	return (
		<AuthLayout>
			<Form />
		</AuthLayout>
	);
};
