import React, { FC, Fragment, useEffect, useState } from 'react';
import styles from './filterGoods.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TFilterGoods } from 'services/store/interfaces';
import { getGoodsFilter } from 'services/utils/js/getArrFilter';
import classNames from 'classnames';
import { InputText } from 'components/Common/Input/InputText';
import { defaultListGoods, hideGoodsFilter, hideOrderFilter } from 'services/store/filter';
import { Button } from 'components/Filter/Actions/Button';
import { Actions } from 'components/Filter/Actions';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';

interface IFilterGoodsProps {
	onChange?: (filters: TFilterGoods) => void;
	type?: 'order' | 'goods';
	onSet?: () => void;
}
export const FilterGoods: FC<IFilterGoodsProps> = ({ type = 'goods', onChange = () => {}, onSet = () => {} }) => {
	const { t } = useTranslation();
	const filtersGoods = useSelector((state: IStoreState) => state.filter.listGoods);
	const filterOrderGoods = useSelector((state: IStoreState) => state.filter.listOrderGoods);

	const isCleanGoods = useSelector((state: IStoreState) => state.filter.isCleanGoods);
	const isCleanOrder = useSelector((state: IStoreState) => state.filter.isCleanOrder);

	const breakpoints = useBreakpoint();
	const dispatch = useDispatch();
	const isMobile = breakpoints.includes('xs');

	const currentFilterValues = {
		order: filterOrderGoods,
		goods: filtersGoods,
	};

	const isCleanMap = {
		order: isCleanOrder,
		goods: isCleanGoods,
	};

	const mapHideFilters = {
		order: () => dispatch(hideOrderFilter()),
		goods: () => dispatch(hideGoodsFilter()),
	};

	const [fields, setFilters] = useState(currentFilterValues[type]);

	const changeFilters = (id: string, value: string) => {
		setFilters({ ...fields, [id]: getGoodsFilter(id, value) });
	};

	const handlerClear = () => setFilters({ ...defaultListGoods });
	const handlerClose = () => mapHideFilters[type]();

	useEffect(() => onChange(fields), [fields]);
	useEffect(() => handlerClear(), [isCleanMap[type]]);

	return (
		<Fragment>
			<div className={styles.filter}>
				<div className={classNames(styles.item)}>
					<span className={styles.item_label}>{t('goods.settings.GoodsId')}</span>
					<InputText onChange={(value) => changeFilters('GoodsId', value)} value={fields.GoodsId?.join(', ')} />
				</div>

				<div className={classNames(styles.item)}>
					<span className={styles.item_label}>{t('goods.settings.GoodsName')}</span>
					<InputText onChange={(value) => changeFilters('GoodsName', value)} value={fields.GoodsName} />
				</div>
			</div>

			<Actions
				confirmButton={<Button title={`${t('actions.use')} ${isMobile ? t('filters.filter') : ''}`} onClick={onSet} />}
				clearButton={
					<Button title={`${t('actions.clean')} ${isMobile ? t('filters.filter') : ''}`} onClick={handlerClear} />
				}
				closeButton={<Button title={t('actions.close')} onClick={handlerClose} />}
			/>
		</Fragment>
	);
};
