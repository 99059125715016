import React, { FC, Fragment, useEffect, useState } from 'react';
import styles from './iconsDesktop.module.scss';
import { Submenu } from '../../Submenu';
import { UserMenu } from '../../UserMenu';
import { MessagesMenu } from '../../MessagesMenu';
import { Nullable } from 'services/store/interfaces';
import { ReactComponent as HelpSVG } from 'assets/images/header/help.svg';
import { ReactComponent as UserSVG } from 'assets/images/header/user.svg';
import { ReactComponent as LangSVG } from 'assets/images/header/lang.svg';
import classNames from 'classnames';
import { OutsideClicker } from 'components/HOCS/OutsideClicker';
import { HelpMenu } from '../../HelpMenu';
import { EventsIcon } from '../EventsIcon';
import { LangMenu } from '../../LangMenu';
import { useAuthorised } from 'services/hooks/useAuthorised';

const ICONSAUTHDESKTOP = [
	{ id: 1, note: 'messages', element: <EventsIcon /> },
	{ id: 2, note: 'help', element: <HelpSVG /> },
	{ id: 3, note: 'lang', element: <LangSVG /> },
	{ id: 4, note: 'user', element: <UserSVG /> },
];

const ICONSUNAUTHDESKTOP = [{ id: 3, note: 'lang', element: <LangSVG /> }];

interface IIconsItems {
	type: Nullable<string>;
	onChange: (value: Nullable<string>) => void;
}

const IconsItems: FC<IIconsItems> = ({ type, onChange }) => {
	const isAuthorised = useAuthorised();
	const ICONSDESKTOP = isAuthorised ? ICONSAUTHDESKTOP : ICONSUNAUTHDESKTOP;

	return (
		<Fragment>
			{ICONSDESKTOP.map(({ id, note, element }) => {
				return (
					<div
						key={id}
						className={classNames(styles.item, { [styles.active]: type === note })}
						onClick={(e) => {
							e.stopPropagation();
							onChange(note);
						}}
					>
						{element}
					</div>
				);
			})}
		</Fragment>
	);
};

export const IconsDesktop = () => {
	const isAuthorised = useAuthorised();
	const [type, setTypeSubmenu] = useState<Nullable<string>>(null);

	useEffect(() => {
		document.addEventListener('scroll', () => setTypeSubmenu(null));

		return () => {
			document.removeEventListener('scroll', () => setTypeSubmenu(null));
		};
	}, []);

	return (
		<div className={styles.icons}>
			<IconsItems type={type} onChange={setTypeSubmenu} />

			<OutsideClicker onOutside={() => setTypeSubmenu('close')}>
				<Submenu type={type}>
					<div
						className={classNames(styles.submenu, {
							[styles.submenu__unAuth]: !isAuthorised,
						})}
					>
						<IconsItems type={type} onChange={setTypeSubmenu} />
					</div>

					<div className={styles.submenu__list}>
						{type === 'user' && <UserMenu />}
						{type === 'messages' && <MessagesMenu />}
						{type === 'help' && <HelpMenu />}
						{type === 'lang' && <LangMenu />}
					</div>
				</Submenu>
			</OutsideClicker>
		</div>
	);
};
