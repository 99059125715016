import React from 'react';
import styles from './homeText.module.scss';
import { useTranslation } from 'react-i18next';

export const HomeText = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.homeText}>
			<h1 className={styles.homeText__header}>{t('home.welcome')}</h1>
			<p className={styles.homeText__text}>{t('home.text')}</p>
		</div>
	);
};
