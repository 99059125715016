import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TFilterEvents } from 'services/store/interfaces';
import dayjs from 'dayjs';
import classNames from 'classnames';
import styles from './filterEvents.module.scss';
import { Button } from 'components/Filter/Actions/Button';
import { Actions } from 'components/Filter/Actions';
import { defaultListEvents, hideEventsFilter } from 'services/store/filter';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { DateFields } from 'components/Common/DateFields';
import { setUpdateSidebarCount } from 'services/store/notifications';
import { useTranslation } from 'react-i18next';

interface IFilterEventsProps {
	onChange?: (filters: TFilterEvents) => void;
	onSet?: () => void;
	isNotifications?: boolean;
}
export const FilterEvents = ({ onChange = () => {}, onSet = () => {} }: IFilterEventsProps) => {
	const { t } = useTranslation();
	const filtersEvents = useSelector((state: IStoreState) => state.filter.listEvents);
	const isCleanFilters = useSelector((state: IStoreState) => state.filter.isCleanEvents);

	const [fields, setFilters] = useState(filtersEvents);
	const [isChangeBegin, setClearBegin] = useState(false);
	const [isChangeEnd, setClearEnd] = useState(false);

	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');
	const dispatch = useDispatch();
	const onBegin = (value: unknown, dateString: string) => {
		setFilters({ ...fields, DateBegin: dateString });
		setClearBegin(true);
	};

	const onEnd = (value: unknown, dateString: string) => {
		const isEmptyBegin = fields.DateBegin === '';
		const isEmptyEnd = dateString === '';
		const DateEnd = isEmptyBegin ? '' : isEmptyEnd ? dayjs().toISOString().slice(0, 10) : dateString;

		setFilters({ ...fields, DateEnd });
		setClearEnd(true);
	};

	const handlerClear = () => {
		dispatch(setUpdateSidebarCount(true));

		setFilters(defaultListEvents);
		setClearBegin(false);
		setClearEnd(false);
	};

	const handlerClose = () => dispatch(hideEventsFilter());

	useEffect(() => onChange(fields), [fields]);
	useEffect(() => handlerClear(), [isCleanFilters]);

	return (
		<Fragment>
			<div className={styles.filter}>
				<div className={classNames(styles.item)}>
					<span className={styles.item_label}>{t('events.period')}</span>

					<DateFields
						key="datefields"
						onBegin={onBegin}
						onEnd={onEnd}
						begin={isChangeBegin ? dayjs(fields.DateBegin) : undefined}
						end={isChangeEnd ? dayjs(fields.DateEnd) : undefined}
					/>
				</div>
			</div>

			<Actions
				confirmButton={<Button title={`${t('actions.use')} ${isMobile ? t('filters.filter') : ''}`} onClick={onSet} />}
				clearButton={
					<Button title={`${t('actions.clean')} ${isMobile ? t('filters.filter') : ''}`} onClick={handlerClear} />
				}
				closeButton={<Button title={t('actions.close')} onClick={handlerClose} />}
			/>
		</Fragment>
	);
};
