import React, { useEffect } from 'react';
import styles from './filter.module.scss';
import { FilterButton } from './FilterButton';
import { FilterList } from './FilterList';
import { Dropdown } from 'components/Common/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState, TFilterTypes } from 'services/store/interfaces';
import useBreakpoint from 'services/hooks/useBreakpoint';
import { ClearFilter } from 'components/Common/ActionButton/ClearFilter';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { mapHide, mapIsShow, mapSetTitle, mapShow } from './map';
import { useLanguage } from '../../services/contexts/languageContext';

interface IFilterProps {
	type: TFilterTypes | 'downTimes';
}

export const Filter = ({ type }: IFilterProps) => {
	const { t } = useTranslation();
	const language = useLanguage();
	const target = useSelector((state: IStoreState) => state.outside.target) as Element;
	const isEvents = ['events', 'notifications', 'downTimes'].includes(type);

	const breakpoints = useBreakpoint();
	const isMobile = breakpoints.includes('xs');
	const isDesktop = !isMobile;

	const dispatch = useDispatch();

	useEffect(() => {
		if (!isDesktop || !target) return;

		const isTarget = typeof target.className.includes !== 'undefined';
		const isClickedSelect = isTarget && target.className.includes('select__item');
		const isClickedDate = isTarget && target.className.includes('ant-picker');

		if (isClickedSelect || isClickedDate) return;

		const list = document.querySelector('[class*=filterList]');
		const button = document.querySelector('[class*=filterButton]');
		const isHided = list && button && !list.contains(target as Node) && !button.contains(target as Node);

		if (isHided) mapHide(dispatch)[type]();
	}, [target]);

	useEffect(() => {
		const items = Array.from(document.querySelectorAll('[class*="item_line__item"]'));
		if (items.length === 0) return;

		const first = items.length === 2 ? 0 : 2;
		const last = items.length > 2 ? 3 : 1;

		(items[first] as HTMLElement).title = language === 'ru' ? 'с' : 'from';
		(items[last] as HTMLElement).title = language === 'ru' ? 'по' : 'to';
	}, [mapIsShow(type), language]);

	return (
		<div
			className={classNames(styles.filter, {
				[styles.filter__active]: mapIsShow(type),
			})}
		>
			<Dropdown
				isOpen={mapIsShow(type)}
				button={
					<FilterButton
						isMobile={isMobile}
						title={isMobile ? '' : t(mapSetTitle[type])}
						onClick={mapShow(dispatch)[type]}
						isEvents={isEvents}
					/>
				}
			>
				{type !== 'downTimes' && <FilterList type={type} />}
			</Dropdown>

			<ClearFilter type={type} isOpen={mapIsShow(type)} />
		</div>
	);
};
