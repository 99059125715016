import React, { FC } from 'react';
import styles from './links.module.scss';
import { LINKS } from '../links';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type TLinksProps = {
	mix?: object;
};

export const Links: FC<TLinksProps> = ({ mix }) => {
	const { t } = useTranslation();

	return (
		<div className={classNames(styles.links, mix)}>
			{LINKS.map(({ id, text, link }) => {
				const isExternalLink = link.includes('http');

				return (
					<div key={id} className={styles.links_item}>
						{isExternalLink ? (
							<a target="_blank" href={link} rel="noreferrer">
								{t(text)}
							</a>
						) : (
							<Link to={link}>{t(text)}</Link>
						)}
					</div>
				);
			})}

			<div className={styles.links_copyright}>
				© {new Date().getFullYear()} {t('rightMenu.ooo')}
			</div>
		</div>
	);
};
