import React, { FC, Fragment, useState } from 'react';
import styles from './iconsMobile.module.scss';
import { Submenu } from '../../Submenu';
import { Nullable } from 'services/store/interfaces';
import classNames from 'classnames';
import { ReactComponent as HamburgerSVG } from 'assets/images/header/hamburger.svg';
import { ReactComponent as CloseSVG } from 'assets/images/header/close.svg';
import { ReactComponent as HelpSVG } from 'assets/images/header/help.svg';
import { ReactComponent as LangSVG } from 'assets/images/header/lang.svg';
import { ReactComponent as UserSVG } from 'assets/images/header/user.svg';

import { MessagesMenu } from '../../MessagesMenu';
import { UserMenu } from '../../UserMenu';
import { EventsIcon } from '../EventsIcon';
import { useAuthorised } from 'services/hooks/useAuthorised';
import { LangMenu } from '../../LangMenu';
import { HelpMenu } from '../../HelpMenu';

const ICONSUNAUTHMENU = [{ id: 1, note: 'lang', element: <LangSVG /> }];

const ICONSAUTHMENU = [
	{ id: 1, note: 'messages', element: <EventsIcon /> },
	{ id: 2, note: 'lang', element: <LangSVG /> },
	{ id: 3, note: 'user', element: <HamburgerSVG /> },
];

const ICONSAUTHSUBMENU = [
	{ id: 1, note: 'messages', element: <EventsIcon /> },
	{ id: 2, note: 'lang', element: <LangSVG /> },
	{ id: 3, note: 'help', element: <HelpSVG /> },
	{ id: 4, note: 'user', element: <UserSVG /> },
	{ id: 5, note: 'close', element: <CloseSVG /> },
];

const ICONSUNAUTHSUBMENU = [
	{ id: 1, note: 'lang', element: <LangSVG /> },
	{ id: 2, note: 'close', element: <CloseSVG /> },
];

interface IIconsItems {
	type: Nullable<string>;
	onChange: (value: Nullable<string>) => void;
}

const IconsItems: FC<IIconsItems> = ({ type, onChange }) => {
	const isAuthorised = useAuthorised();
	const ICONSMENU = isAuthorised ? ICONSAUTHMENU : ICONSUNAUTHMENU;

	return (
		<Fragment>
			{ICONSMENU.map(({ id, note, element }) => {
				return (
					<div
						key={id}
						className={classNames(styles.item, { [styles.active]: type === note })}
						onClick={() => onChange(note)}
					>
						{element}
					</div>
				);
			})}
		</Fragment>
	);
};

export const IconsMobile = () => {
	const isAuthorised = useAuthorised();
	const [type, setTypeSubmenu] = useState<Nullable<string>>(null);
	const ICONSUBMENU = isAuthorised ? ICONSAUTHSUBMENU : ICONSUNAUTHSUBMENU;

	return (
		<div className={styles.icons}>
			<IconsItems type={type} onChange={setTypeSubmenu} />

			<Submenu type={type}>
				<div className={styles.submenu}>
					<div className={styles.submenu_left}>
						{ICONSUBMENU.filter(({ note }) => note !== 'close').map(({ id, note, element }) => (
							<div
								key={id}
								className={classNames(styles.item, { [styles.active]: type === note })}
								onClick={() => {
									setTypeSubmenu(note);
								}}
							>
								{element}
							</div>
						))}
					</div>
					<div className={styles.submenu_right}>
						{ICONSUBMENU.filter(({ note }) => note === 'close').map(({ id, note, element }) => (
							<div
								key={id}
								className={classNames(styles.item, { [styles.active]: type === note })}
								onClick={() => {
									setTypeSubmenu(note);
								}}
							>
								{element}
							</div>
						))}
					</div>
				</div>

				<div className={styles.submenu__list}>
					{type === 'user' && <UserMenu />}
					{type === 'messages' && <MessagesMenu />}
					{type === 'lang' && <LangMenu />}
					{type === 'help' && <HelpMenu />}
				</div>
			</Submenu>
		</div>
	);
};
