import React, { useEffect } from 'react';
import styles from './types.module.scss';
import classNames from 'classnames';
import { ReactComponent as IconTable } from 'assets/images/planning/table.svg';
import { ReactComponent as IconSchedule } from 'assets/images/planning/graphic.svg';
import { IStoreState } from 'services/store/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGetGoodsForFilter, fetchGetMonthForFilter, setIsTablePlanning } from 'services/store/reports';
import { useTranslation } from 'react-i18next';

export const TypesPlanning = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { isPlanningTable } = useSelector((state: IStoreState) => state.reports);

	useEffect(() => {
		dispatch(fetchGetGoodsForFilter({ GoodsName: '', GoodsId: [], PageNumber: 1, Limit: 10000 }));
		dispatch(fetchGetMonthForFilter({ ContractorId: localStorage.getItem('userId') || '' }));
	}, []);

	return (
		<div className={styles.panel}>
			<div
				className={classNames(styles.panel_type, styles.panel_table, {
					[styles.panel_active]: isPlanningTable,
				})}
				onClick={() => dispatch(setIsTablePlanning(true))}
			>
				<IconTable />
				<p className={styles.panel_text}>{t('reports.table')}</p>
			</div>

			<div
				className={classNames(styles.panel_type, styles.panel_schedule, {
					[styles.panel_active]: !isPlanningTable,
				})}
				onClick={() => dispatch(setIsTablePlanning(false))}
			>
				<IconSchedule />
				<p className={styles.panel_text}>{t('reports.chart')}</p>
			</div>
		</div>
	);
};
